import { Progress } from "antd";
import { MdOutlineDescription } from "react-icons/md";
import Button from "shared/components/Button";

const Percent = ({ element }) => {
  return (
    <div className="__file">
      <div>
        <div>
          <MdOutlineDescription size={20} />
          {element.filename}
          <span>{element.percentageLabel}</span>
        </div>
        <Progress
          percent={element.percentage}
          size={"small"}
          showInfo={false}
        />
      </div>
      <div>
        {element.actions?.map(({ icon, text, onClick }, index) => (
          <Button
            key={`action_${index}`}
            type="black"
            alignment="center"
            onClick={onClick}
          >
            {icon} {text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Percent;
