import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  INITIAL_VALUES,
  NAVIGATION_DIRECTIONS,
  ORGANIZATION_TYPES,
} from "utils/constants";
import View from "./View";

const Delete = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [visible, setVisible] = useState(true);
  const cancel = () => navigate(NAVIGATION_DIRECTIONS.BACK);
  const [getUsers, { loading }] = useLazyQuery(graphql.queries.USERS);
  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER_AUTH);
  const [deleteOrganization] = useMutation(
    graphql.mutations.DELETE_ORGANIZATION,
    {
      refetchQueries: [
        {
          query: graphql.queries.ORGANIZATIONS,
          awaitRefetchQueries: true,
          variables: {
            where: {
              type: ORGANIZATION_TYPES.INS,
            },
            ...INITIAL_VALUES,
          },
        },
      ],
      onCompleted: () => {
        setVisible(false);
        navigate(NAVIGATION_DIRECTIONS.BACK, { replace: true });
      },
    }
  );
  const onSubmit = async () => {
    const { data: usersData } = await getUsers({
      variables: {
        where: { organization: { id } },
      },
    });

    await Promise.all(
      usersData?.users?.data?.map(async ({ id }) => {
        await updateUser({
          variables: {
            where: { id },
            data: {
              phone: null,
              username: null,
              email: null,
              password: null,
            },
          },
        });
      })
    );
    return deleteOrganization({
      variables: {
        where: {
          id,
        },
      },
    });
  };

  if (loading) return <Loading />;

  return <View onSubmit={onSubmit} cancel={cancel} visible={visible} />;
};

export default Delete;
