import { Col, Row } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineAccountBalanceWallet,
  MdOutlineCallReceived,
} from "react-icons/md";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";

const BankAccount = ({
  values,
  onFileRemove,
  setFieldValue,
  setPercent,
  disabled,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div className="subscription-form--wrapper">
      <Form type="vertical" title={SUBSCRIPTION.FORM.PROOF.BANK_ACCOUNT.TITLE}>
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <div className="subscription-form--group-label">
              <MdOutlineAccountBalanceWallet size={16} />
              <span className="group-label--text">
                {SUBSCRIPTION.FORM.PROOF.PAYMENT_ACCOUNT}
              </span>
            </div>
          </Col>
          <Col lg={24} xs={24}>
            <div className="subscription-form--custom-upload">
              <Input
                type="file"
                variant="uploader"
                listType="picture"
                multiple
                onUpload={(fileList) => {
                  setFieldValue("proof.paymentAccount", fileList);
                  setPercent({
                    ...values,
                    proof: { ...values.proof, paymentAccount: fileList },
                  });
                }}
                validTypes={SUBSCRIPTION.FORM.PROOF.ALLOWED_FILES_TYPE}
                onFileRemove={onFileRemove}
                fileList={get(values, "proof.paymentAccount", [])}
              >
                <Row align="center">
                  <Button type="primary--link">
                    {SUBSCRIPTION.FORM.PROOF.ACTION.ADD}
                  </Button>
                </Row>
              </Input>
            </div>
          </Col>
        </Row>
        {!get(disabled, "proof.refundAccount") && (
          <Row>
            <Col lg={24} xs={24}>
              <div className="subscription-form--group-label">
                <MdOutlineCallReceived size={16} key="call_received" />
                <span className="group-label--text">
                  {SUBSCRIPTION.FORM.PROOF.REFUND_ACCOUNT}
                </span>
              </div>
            </Col>
            <Col lg={24} xs={24}>
              <div className="subscription-form--custom-upload">
                <Input
                  type="file"
                  variant="uploader"
                  maxCount={1}
                  onUpload={(fileList) => {
                    setFieldValue("proof.refundAccount", fileList);
                    setPercent({
                      ...values,
                      proof: { ...values.proof, refundAccount: fileList },
                    });
                  }}
                  validTypes={SUBSCRIPTION.FORM.PROOF.ALLOWED_FILES_TYPE}
                  onFileRemove={onFileRemove}
                  fileList={get(values, "proof.refundAccount", [])}
                >
                  <Row align="center">
                    <Button type="primary--link">
                      {SUBSCRIPTION.FORM.PROOF.ACTION.ADD}
                    </Button>
                  </Row>
                </Input>
              </div>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default BankAccount;
