import cuid from "cuid";
import { useFormik } from "formik";
import { get, isNil, range } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft } from "react-icons/bi";
import { FiArrowUpCircle } from "react-icons/fi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import Select from "shared/components/Select";
import Loading from "shared/components/Spin";
import { useLazyQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import {
  BROKERS,
  BROKERS_WITHOUT_COMMISSION_STATEMENTS,
} from "utils/api/graphql/queries/brokers";
import {
  ATTACHMENT_TYPES,
  INITIAL_VALUES,
  MONTH_OPTIONS,
  ORGANIZATION_TYPES,
} from "utils/constants";
import { joinList } from "utils/helpers/array";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel, data: brokers }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const MONTHS = t("MONTHS", { returnObjects: true });
  const FILTER = t("FILTER", { returnObjects: true });
  const yearsList = range(2023, moment().year() + 3);
  const { isValid, handleSubmit, values, setFieldValue, ...formProps } =
    useFormik({
      initialValues: {},
      onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      ),
    });
  const [uploadBrokers, setUploadBrokers] = useState([]);
  const [pagination, setPagination] = useState({ ...INITIAL_VALUES });
  const [search, setSearch] = useState();
  const [getCommissionStatements, { loading }] = useLazyQuery(
    BROKERS_WITHOUT_COMMISSION_STATEMENTS
  );
  const onSearch = (socialReason) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setSearch(socialReason);
  };

  useEffect(() => {
    if (!values.month || !values.year) return;
    getCommissionStatements({
      variables: {
        orderBy: [{ socialReason: "ASC" }],
        where: {
          OR: [
            { organization: { type: ORGANIZATION_TYPES.BROKER } },
            { isInternal: true },
          ],
          socialReason: search,
          commissionStatement: {
            month: values.month,
            year: values.year,
          },
        },
        take: pagination.take,
        skip: pagination.skip,
      },
      onCompleted: ({
        brokersWithoutCommissionStatements: { data, count },
      }) => {
        setPagination((prev) => ({ ...prev, count }));
        setUploadBrokers(data);
      },
    });
  }, [
    brokers,
    getCommissionStatements,
    values.month,
    values.year,
    search,
    pagination.skip,
    pagination.take,
  ]);

  return (
    <div className="commission-statements">
      <Header
        key={"header"}
        title={CREATE.HEADER.TITLE}
        subtitle={CREATE.HEADER.ACTION}
        actions={[
          {
            text: CREATE.HEADER.DISCARD,
            onClick: cancel,
            type: "primary--outlined",
            icon: <BiChevronLeft size={20} />,
          },
          {
            text: CREATE.HEADER.SAVE,
            onClick: handleSubmit,
            type: "primary",
            icon: <BiCheck size={20} />,
          },
        ]}
      />
      <div className="commission-statements-filter">
        <span>{FILTER.SELECT_DATE}</span>
        <Select
          options={yearsList.map((year) => ({
            value: `${year}`,
            label: year,
          }))}
          placeholder={FILTER.YEAR}
          {...bindInputProps({ name: "year", values, ...formProps })}
        />
        <Select
          placeholder={FILTER.MONTH}
          options={MONTH_OPTIONS.map((value) => ({
            value,
            label: MONTHS[value],
          }))}
          {...bindInputProps({ name: "month", values, ...formProps })}
        />
      </div>

      <Card key="card" type={"simple"}>
        {get(values, "month") && get(values, "year") ? (
          <div className="grid--1">
            <div className="d-flex justify--end">
              <span>{FILTER.FILTER_BY_BROKER}</span>
              <Select
                type="async"
                node={{
                  query: BROKERS,
                  variables: {
                    where: {
                      OR: [
                        { organization: { type: ORGANIZATION_TYPES.BROKER } },
                        { isInternal: true },
                      ],
                    },
                  },
                  accessors: {
                    root: "brokers.data",
                    label: "socialReason",
                    value: "socialReason",
                  },
                }}
                allowClear
                value={search}
                placeholder={FILTER.BROKER}
                onChange={onSearch}
              />
            </div>
            <div className="commission-statements-header">
              <div>{CREATE.BROKERS}</div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              uploadBrokers.map(({ id, socialReason, orias }, i) => (
                <div className="commission-statements-row" key={id}>
                  <span key={`broker-${i}`}>
                    {joinList([socialReason, orias], "-")}
                  </span>
                  <Input
                    key={`upload-${i}`}
                    type="file"
                    variant="uploader"
                    listType="text"
                    maxCount={1}
                    fileList={[
                      get(values, `${values.year}.${values.month}.${id}`, null),
                    ].filter((file) => !isNil(file))}
                    onUpload={([file]) => {
                      if (!file) {
                        return setFieldValue(
                          `${values.year}.${values.month}.${id}`,
                          undefined
                        );
                      }
                      const { base64, contentType, name } = file;
                      setFieldValue(`${values.year}.${values.month}.${id}`, {
                        id: cuid(),
                        content: base64,
                        contentType,
                        name,
                        type: ATTACHMENT_TYPES.COMMISSION_STATEMENT,
                      });
                    }}
                  >
                    <Button type="primary--link">
                      <FiArrowUpCircle size={16} />
                      {FORM.UPLOAD}
                    </Button>
                  </Input>
                </div>
              ))
            )}
            {get(pagination, "count", 0) > pagination.take && (
              <Pagination
                count={pagination.count}
                setValues={setPagination}
                skip={pagination.skip}
              />
            )}
          </div>
        ) : (
          <div className="commission-statements-empty">
            <span>{CREATE.EMPTY}</span>
          </div>
        )}
      </Card>
    </div>
  );
};

export default Create;
