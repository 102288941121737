import { Col, Row, notification } from "antd";
import { get } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  MdArrowForwardIos,
  MdEuro,
  MdInfo,
  MdOutlineAssignment,
  MdOutlineBusinessCenter,
  MdOutlineChildCare,
  MdOutlineFavorite,
  MdOutlineFrontHand,
  MdOutlineLocationOn,
  MdOutlinePayment,
  MdOutlinePerson,
  MdOutlineShareLocation,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import { useQuery } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import format from "string-template";
import { ADDRESS_PATH_TYPES } from "utils/api/graphql/queries/referentials";
import { STEPPER_KEYS } from "../..";
import Progress from "../Progress";
import { generateFormMap } from "./Forms";

const ICONS = {
  CONTRACT: <MdOutlineAssignment size={16} />,
  TERMINATION_REQUEST: <MdOutlineFrontHand size={16} />,
  BROKERAGE_MANDATE: <MdOutlineShareLocation size={16} />,
  ADHERENT: <MdOutlinePerson size={16} />,
  ACTIVITY: <MdOutlineBusinessCenter size={16} />,
  CONTACT: <MdOutlineLocationOn size={16} />,
  PARTNER: <MdOutlineFavorite size={16} />,
  CHILDREN: <MdOutlineChildCare size={16} />,
  PAYMENT: <MdOutlinePayment size={16} />,
  REFUND: <MdEuro size={16} />,
  INFORMATION: <MdInfo size={16} />,
};

const LOCKED_STYLE = {
  [true]: "locked",
  [false]: "",
};

const AdditionalInformation = ({
  subscription,
  progress,
  disabled,
  setDisabled,
  setPercent,
  subscriptionForm,
  setActive,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  const formList = useMemo(
    () =>
      Object.entries(generateFormMap(subscription)).filter(([_, Form]) => Form),
    []
  );

  const slideTo = (elementRef) => {
    document
      .getElementById(`${elementRef}-section`)
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const { isValid, handleSubmit, setFieldValue, values, ...formProps } =
    subscriptionForm;

  const [options, setOptions] = useState([]);
  const { data } = useQuery(ADDRESS_PATH_TYPES, {
    variables: { where: {} },
    onCompleted: (data) => setOptions(get(data, "addressPathTypes.response")),
  });

  const handleSearch = (value) => {
    const filteredOptions = get(data, "addressPathTypes.response", []).filter(
      (option) => option.label.toLowerCase().includes(value.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  return (
    <div className={LOCKED_STYLE[subscription.locked]}>
      <div
        key="additional-information"
        className="subscription-additional-information--wrapper"
      >
        <Row gutter={[16, 16]} key={"row"}>
          <Col lg={24} xs={24}>
            <Row align="middle" gutter={[16, 16]}>
              <Col lg={18} xs={24}>
                <Alert
                  type="warning"
                  showIcon
                  message={SUBSCRIPTION.ADDITIONAL_INFORMATION.WARNING}
                />
              </Col>
              <Col align="end" lg={6} xs={24}>
                <span className="indicator-step">
                  {format(SUBSCRIPTION.INDICATOR, {
                    count: "1",
                    total: "3",
                  })}
                </span>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <Row gutter={[16, 16]}>
              <Col lg={9} xs={24}>
                <div className="card-skeleton--container highlighted">
                  <div className="card-skeleton--wrapper">
                    <h3 className="title--md">
                      {SUBSCRIPTION.ADDITIONAL_INFORMATION.TITLE}
                    </h3>
                    <div className="card-skeleton--wrapper__progress-list">
                      {formList.map(([form], index) => (
                        <Progress
                          key={"progress-" + index}
                          percent={progress["ADDITIONAL_INFORMATION"][form]}
                          translation={SUBSCRIPTION}
                          form={form}
                          slideTo={slideTo}
                          icon={ICONS[form]}
                          title={
                            SUBSCRIPTION.ADDITIONAL_INFORMATION.STEPS[form]
                          }
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={15} xs={24}>
                <Card
                  title={SUBSCRIPTION.ADDITIONAL_INFORMATION.TITLE}
                  subtitle={SUBSCRIPTION.ADDITIONAL_INFORMATION.SUBTITLE}
                >
                  {formList.map(([key, Form], index) => (
                    <Form
                      id={"section-" + key}
                      key={"form-" + index}
                      values={values}
                      progress={progress}
                      disabled={disabled}
                      setDisabled={setDisabled}
                      formProps={formProps}
                      setFieldValue={setFieldValue}
                      setPercent={(values) =>
                        setPercent(
                          STEPPER_KEYS.ADDITIONAL_INFORMATION,
                          key,
                          values
                        )
                      }
                      streetType={{ options, handleSearch }}
                      isFieldRequired={(field) => isFieldRequired(key, field)}
                    />
                  ))}
                </Card>
              </Col>
              <Col lg={24} xs={24}>
                <Row align="end">
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!isValid) {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        notification.error({
                          message: SUBSCRIPTION.ADDITIONAL_INFORMATION.ERROR,
                          duration: 5,
                          placement: "bottomRight",
                          style: {
                            backgroundColor: COLORS.C_DANGER_BACKGROUND,
                          },
                        });
                      } else setActive(STEPPER_KEYS.PROOF);
                      handleSubmit();
                    }}
                  >
                    {SUBSCRIPTION.ADDITIONAL_INFORMATION.ACTION.SAVE}
                    <MdArrowForwardIos size={12} />
                  </Button>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdditionalInformation;
