import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { MdOutlineDeleteOutline, MdOutlineOpenInNew } from "react-icons/md";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";

const List = ({ data, onDetail, onDelete, count, setValues, skip }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION);
  const LIST = t("LIST", { returnObjects: true });

  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "name",
      render: (name) => name || "-",
    },

    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <div className="d-flex justify--end">
          <MdOutlineOpenInNew
            size={24}
            color={COLORS.C_PRIMARY}
            onClick={() => onDetail(id)}
          />
          <MdOutlineDeleteOutline
            size={24}
            color={COLORS.C_DANGER}
            onClick={() => onDelete(id)}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        columns={columns}
        className="table__wrapper"
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
};

export default List;
