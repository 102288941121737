import cuid from "cuid";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "shared/hooks/useApi";
import { useScreenActionPermissions } from "shared/hooks/usePermissions";
import graphql from "utils/api/graphql";
import { CREATE_PROJECT } from "utils/api/graphql/mutations/projects";
import { EXPORT_SUBSCRIPTIONS } from "utils/api/graphql/mutations/subscription";
import { TRACER } from "utils/api/graphql/queries/tracers";
import {
  CONTENT_TYPES,
  INITIAL_VALUES,
  MODULES,
  SCREENS,
  SUBMODULES,
  TRACER_TYPES,
} from "utils/constants";
import { downloadDocument } from "utils/helpers/files";
import View from "./View";

const List = () => {
  const navigate = useNavigate();

  const actions = useScreenActionPermissions({
    module: MODULES.PROJECT,
    submodule: SUBMODULES.BROKER_LIST,
  });

  const [duplicateProject] = useMutation(graphql.mutations.DUPLICATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: ({ duplicateProject }) => {
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${duplicateProject.id}`
      );
    },
  });

  const [exportSubscriptions] = useMutation(EXPORT_SUBSCRIPTIONS);

  const handleExportSubscriptions = async () => {
    const { data } = await exportSubscriptions();
    downloadDocument({
      base64: data.exportSubscriptions,
      contentType: CONTENT_TYPES.XLSX,
      name: "subscriptions.xlsx",
    });
  };

  const onDetail = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${id}`);

  const onDelete = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.PROJECTS.DELETE.path}/${id}`);

  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({});

  const onSearch = (target) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      like: {
        ...prev.like,
        OR: [
          {
            contact: {
              user: {
                firstname: `%${target.value}%`,
              },
            },
          },
          {
            contact: {
              user: {
                lastname: `%${target.value}%`,
              },
            },
          },
          { author: { firstname: `%${target.value}%` } },
          { author: { lastname: `%${target.value}%` } },
        ],
      },
    }));
  };

  const applyFilter = (filter) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      isIn: {
        ...prev.isIn,
        ...filter,
      },
    }));
  };

  const [addProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [
      {
        query: graphql.queries.PROJECTS,
        awaitRefetchQueries: true,
        variables: INITIAL_VALUES,
      },
    ],
    onCompleted: ({ addProject }) => {
      navigate(
        `${SCREENS.PRIVATE.BROKER.PROJECTS.DETAIL.path}/${addProject.id}`
      );
    },
  });
  const [getTracer] = useLazyQuery(TRACER, {
    variables: { where: { type: TRACER_TYPES.COMPARISON } },
  });

  const createProject = () => {
    getTracer({
      onCompleted: ({ tracer }) => {
        const status = omitDeep(
          get(tracer, "flow.process.processStatuses"),
          "__typename"
        ).reduce((previous, current) =>
          Number(previous?.order) < Number(current.order) ? previous : current
        );
        addProject({
          variables: {
            data: {
              id: cuid(),
              status,
            },
          },
        });
      },
    });
  };

  return (
    <View
      filter={filter}
      onDetail={onDetail}
      pagination={pagination}
      setPagination={setPagination}
      onSearch={onSearch}
      applyFilter={applyFilter}
      duplicate={duplicateProject}
      createProject={createProject}
      onDelete={onDelete}
      actions={actions}
      exportSubscriptions={handleExportSubscriptions}
    />
  );
};

export default List;
