import { Col, Row } from "antd";
import cuid from "cuid";
import { FieldArray, Formik } from "formik";
import { get } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiPlus, BiTrash } from "react-icons/bi";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate } from "react-router";
import Button from "shared/components/Button";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { WORKFLOWS } from "utils/api/graphql/queries/workflows";
import {
  ACTION_TYPES,
  BOOLEAN,
  PROCESS_TAGS,
  SCREENS,
  TEMPLATES,
} from "utils/constants";
import { bindArrayInputProps, bindInputProps } from "utils/helpers/input";
import ConditionsModal from "./ConditionsModal";
import validation from "./validation";

const Detail = ({ onSubmit, data, cancel }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.PROCESS);
  const [modal, setModal] = useState({
    visible: false,
  });
  const { name, processStatuses } = data;
  const navigate = useNavigate();
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const TEMPLATES_LABELS = t("TEMPLATES", { returnObjects: true });
  const TAGS = t("PROCESS_TAGS", { returnObjects: true });

  return (
    <Formik
      initialValues={{
        name,
        processStatuses: [...processStatuses].sort(
          (a, b) => parseInt(a.order) - parseInt(b.order)
        ),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, values, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          subtitle={DETAIL.HEADER.ACTION}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: () =>
                navigate(
                  `${SCREENS.PRIVATE.ADMIN.PROCESSES.DELETE.path}/${data.id}`
                ),
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Row gutter={[16, 16]} key="general">
          <Col span={24}>
            <Form title={FORM.GENERAL_INFO}>
              <Col lg={8}>
                <Input
                  placeholder={FORM.NAME}
                  label={FORM.NAME}
                  {...bindInputProps({ name: "name", values, ...formProps })}
                />
              </Col>
            </Form>
          </Col>
        </Row>,
        <Row gutter={[16, 16]} key="statuses">
          <Col span={24}>
            <Form title={FORM.STATUSES_INFO} key="form">
              <FieldArray
                name={"processStatuses"}
                render={(arrayHelpers) => [
                  values.processStatuses.map((_, i) => [
                    <Col lg={4} key={`name-${_.id}`}>
                      <Input
                        placeholder={FORM.STATUSES.NAME}
                        label={FORM.STATUSES.NAME}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "name",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`color-${_.id}`}>
                      <Input
                        type="color"
                        placeholder={FORM.STATUSES.COLOR}
                        label={FORM.STATUSES.COLOR}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "color",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`background-${_.id}`}>
                      <Input
                        type="color"
                        placeholder={FORM.STATUSES.BACkGROUND}
                        label={FORM.STATUSES.BACkGROUND}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "background",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={4} key={`tag-${_.id}`}>
                      <Select
                        type="simple"
                        label={FORM.STATUSES.TAG}
                        options={Object.entries(PROCESS_TAGS).map(
                          ([key, value]) => ({
                            value,
                            label: TAGS[key],
                          })
                        )}
                        placeholder={FORM.STATUSES.TAG}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "tag",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`progress-${_.id}`}>
                      <Input
                        placeholder={FORM.STATUSES.PROGRESS}
                        label={FORM.STATUSES.PROGRESS}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "progress",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`order-${_.id}`}>
                      <Input
                        placeholder={FORM.STATUSES.ORDER}
                        label={FORM.STATUSES.ORDER}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "order",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={4} key={`template-${_.id}`}>
                      <Select
                        options={Object.entries(TEMPLATES).map(
                          ([key, value]) => ({
                            label: TEMPLATES_LABELS[key],
                            value,
                          })
                        )}
                        placeholder={FORM.STATUSES.TEMPLATE}
                        label={FORM.STATUSES.TEMPLATE}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "template",
                          values,
                          ...formProps,
                        })}
                      />
                    </Col>,
                    <Col lg={2} key={`move-${_.id}`}>
                      <Input
                        type="checkbox"
                        placeholder={FORM.STATUSES.MOVE_TO_NEXT}
                        label={FORM.STATUSES.MOVE_TO_NEXT}
                        {...bindArrayInputProps({
                          parent: "processStatuses",
                          index: i,
                          name: "moveToNext",
                          values,
                          ...formProps,
                        })}
                        checked={values.processStatuses[i].moveToNext}
                      />
                    </Col>,
                    <Col lg={2} key={`delete-${_.id}`}>
                      <div
                        className={"delete-icon"}
                        onClick={() => {
                          arrayHelpers.remove(i);
                        }}
                      >
                        <BiTrash color={COLORS.C_DANGER} size={24} />
                      </div>
                    </Col>,
                  ]),
                  <Button
                    key="add-row"
                    onClick={() => {
                      arrayHelpers.push({ id: cuid() });
                    }}
                    type="primary"
                  >
                    <BiPlus size={24} />
                  </Button>,
                ]}
              />
              ,
            </Form>
          </Col>
        </Row>,
        <Row gutter={[16, 16]} key="possibleStatuses">
          <Col span={24}>
            <Form
              title={FORM.POSSIBLE_STATUSES.POSSIBLE_STATUSES_INFO}
              key="form"
            >
              {values.processStatuses.map((processStatus, index) => [
                <Col span={4} key={`left_${processStatus.id}`}>
                  <h4> {processStatus.name} </h4>
                </Col>,
                <Col span={20} key={`right_${processStatus.id}`}>
                  <Row gutter={[16, 16]}>
                    <FieldArray
                      name={`processStatuses.${index}.possibleStatuses`}
                      render={(arrayHelpers) => [
                        values.processStatuses[index]?.possibleStatuses?.map(
                          (_, i) => [
                            <Col lg={5} key={`nextStatus-${_.id}`}>
                              <Select
                                options={values.processStatuses
                                  .filter(
                                    (status) => status.id !== processStatus.id
                                  )
                                  .map((processStatus) => ({
                                    label: processStatus.name,
                                    value: processStatus.id,
                                  }))}
                                placeholder={FORM.POSSIBLE_STATUSES.NEXT_STATUS}
                                label={FORM.POSSIBLE_STATUSES.NEXT_STATUS}
                                {...bindArrayInputProps({
                                  parent: `processStatuses.${index}.possibleStatuses`,
                                  index: i,
                                  name: "nextStatus",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>,
                            <Col lg={5} key={`conditionLeft-${_.id}`}>
                              <Input
                                placeholder={
                                  FORM.POSSIBLE_STATUSES.CONDITIONS.ACCESSOR
                                }
                                label={
                                  FORM.POSSIBLE_STATUSES.CONDITIONS.ACCESSOR
                                }
                                {...bindArrayInputProps({
                                  parent: `processStatuses.${index}.possibleStatuses`,
                                  index: i,
                                  name: "conditions.0.leftOperand.accessor",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>,
                            <Col lg={5} key={`condition-${_.id}`}>
                              <Input
                                label={
                                  FORM.POSSIBLE_STATUSES.CONDITIONS.OPERATION
                                }
                                placeholder={
                                  FORM.POSSIBLE_STATUSES.CONDITIONS.OPERATION
                                }
                                {...bindArrayInputProps({
                                  parent: `processStatuses.${index}.possibleStatuses`,
                                  index: i,
                                  name: "conditions.0.operation",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>,
                            <Col lg={4} key={`conditionRight-${_.id}`}>
                              <Input
                                placeholder={
                                  FORM.POSSIBLE_STATUSES.CONDITIONS.VALUE
                                }
                                label={FORM.POSSIBLE_STATUSES.CONDITIONS.VALUE}
                                {...bindArrayInputProps({
                                  parent: `processStatuses.${index}.possibleStatuses`,
                                  index: i,
                                  name: "conditions.0.rightOperand.value",
                                  values,
                                  ...formProps,
                                })}
                                onChange={(e) => {
                                  setFieldValue(
                                    `processStatuses.${index}.possibleStatuses.${i}.conditions.0.rightOperand.value`,
                                    BOOLEAN[e.target.value] ?? e.target.value
                                  );
                                }}
                              />
                            </Col>,
                            <Col lg={1} key={`conditionRight_checkbox-${_.id}`}>
                              <div
                                onClick={() => {
                                  setModal({
                                    visible: true,
                                    name: "possibleStatuses",
                                    processStatusIndex: index,
                                    childIndex: i,
                                  });
                                }}
                              >
                                <MdOutlineEdit size={16} />
                              </div>
                            </Col>,
                            <Col lg={2} key={`delete-${_.id}`}>
                              <div
                                className={"delete-icon"}
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                }}
                              >
                                <BiTrash color={COLORS.C_DANGER} size={24} />
                              </div>
                            </Col>,
                          ]
                        ),
                        <Button
                          key="add-row"
                          type="primary"
                          onClick={() => {
                            arrayHelpers.push({
                              id: cuid(),
                              conditions: [
                                {
                                  id: cuid(),
                                  leftOperand: {},
                                  rightOperand: {},
                                },
                              ],
                            });
                          }}
                        >
                          <BiPlus size={24} />
                        </Button>,
                      ]}
                    />
                  </Row>
                </Col>,
                <Col span={4} key={`empty${processStatus.id}`} />,
                <Col span={20} key={`action_${processStatus.id}`}>
                  <div className="grid--1">
                    <FieldArray
                      name={`processStatuses.${index}.actions`}
                      render={(arrayHelpers) => [
                        values.processStatuses[index]?.actions?.map((_, i) => (
                          <Row gutter={[16, 16]} key={_.id}>
                            <Col lg={6}>
                              <Select
                                options={Object.values(ACTION_TYPES).map(
                                  (value) => ({
                                    value,
                                    label: FORM.ACTIONS.ACTION_TYPES[value],
                                  })
                                )}
                                placeholder={FORM.ACTIONS.TYPE}
                                label={FORM.ACTIONS.TYPE}
                                {...bindArrayInputProps({
                                  parent: `processStatuses.${index}.actions`,
                                  index: i,
                                  name: "type",
                                  values,
                                  ...formProps,
                                })}
                              />
                            </Col>
                            {get(
                              values,
                              `processStatuses.${index}.actions.${i}.type`
                            ) === ACTION_TYPES.NAVIGATE && (
                              <Col lg={10}>
                                <Input
                                  label={FORM.ACTIONS.URL}
                                  placeholder={FORM.ACTIONS.URL}
                                  {...bindArrayInputProps({
                                    parent: `processStatuses.${index}.actions`,
                                    index: i,
                                    name: "args.url",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            )}
                            {get(
                              values,
                              `processStatuses.${index}.actions.${i}.type`
                            ) === ACTION_TYPES.WEBHOOK && (
                              <Col lg={6}>
                                <Select
                                  type="async"
                                  label={FORM.ACTIONS.WEBHOOKS}
                                  placeholder={FORM.ACTIONS.WEBHOOKS}
                                  node={{
                                    query: WORKFLOWS,
                                    variables: {},
                                    accessors: { root: "workflows.data" },
                                  }}
                                  {...bindArrayInputProps({
                                    parent: `processStatuses.${index}.actions`,
                                    index: i,
                                    name: "args.workflow",
                                    values,
                                    ...formProps,
                                  })}
                                />
                              </Col>
                            )}
                            <Col lg={1}>
                              <div
                                onClick={() => {
                                  setModal({
                                    visible: true,
                                    name: "actions",
                                    processStatusIndex: index,
                                    childIndex: i,
                                  });
                                }}
                              >
                                <MdOutlineEdit size={16} />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div
                                className={"delete-icon"}
                                onClick={() => {
                                  arrayHelpers.remove(i);
                                }}
                              >
                                <BiTrash color={COLORS.C_DANGER} size={24} />
                              </div>
                            </Col>
                          </Row>
                        )),
                        <Button
                          key="add-row"
                          type="secondary"
                          onClick={() => {
                            arrayHelpers.push({
                              id: cuid(),
                            });
                          }}
                        >
                          <BiPlus size={24} /> {FORM.ACTIONS.ADD}
                        </Button>,
                      ]}
                    />
                  </div>
                </Col>,
              ])}
            </Form>
          </Col>
        </Row>,
        <ConditionsModal
          key="conditions-modal"
          translation={FORM.POSSIBLE_STATUSES.CONDITIONS}
          modal={modal}
          setModal={setModal}
          setFieldValue={setFieldValue}
          values={values}
          formProps={formProps}
        />,
      ]}
    </Formik>
  );
};

export default Detail;
