import moment from "moment";
import PropTypes from "prop-types";
import { BiCalendar, BiChart, BiSolidUser } from "react-icons/bi";
import { BsArrowUpRight } from "react-icons/bs";
import Button from "shared/components/Button";
import { CURRENCY_SYMBOL, DATE_FORMAT } from "utils/constants";
import Popover from "../Popover";

const Subscription = ({
  date,
  user,
  status,
  icon,
  product,
  category,
  tag,
  cost,
  currency,
  period,
  translation,
  onClick = () => {},
  disabled,
}) => {
  const [integer, decimal = "00"] = cost?.toString().split(".") || ["-"];

  return (
    <div className="subscription__card">
      <div className="subscription__card--head">
        <div className="infos">
          <div className="item popover-wrapper">
            <Popover text={translation.ISSUANCE_DATE} small />
            <BiCalendar />
            <span>{moment(date).format(DATE_FORMAT)}</span>
          </div>
          <div className="item">
            <BiSolidUser />
            <span>{user}</span>
          </div>
        </div>
        <div className="status">
          <BiChart />
          <span>{status}</span>
        </div>
      </div>
      <div className="subscription__card--body">
        <div className="product">
          <img src={icon} alt={product} />

          <div className="infos">
            <h4>{product}</h4>
            <span>{category}</span>
            <span className="tag">{tag}</span>
          </div>
        </div>
        <div className="pricing">
          <h4 className="price">
            <span>
              {`${integer} ${CURRENCY_SYMBOL[currency]} `}
              <sup>{decimal?.slice(0, 2)}</sup>
            </span>
            <small>/{period}</small>
          </h4>
          <div className="price--info">
            <span>
              {`${(+cost * 12).toFixed(0)} ${CURRENCY_SYMBOL[currency]} ${
                translation.PER_YEAR
              } `}
            </span>
            {translation.ALL_COST}
          </div>
        </div>
      </div>
      <div className="subscription__card--action">
        <div className="item popover-wrapper">
          {disabled.status && <Popover text={disabled.text} small />}
          <Button
            type={"secondary"}
            icon={<BsArrowUpRight />}
            size={24}
            onClick={onClick}
            disabled={disabled.status}
          >
            {translation.RESUME}
          </Button>
        </div>
      </div>
    </div>
  );
};

Subscription.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
};

Subscription.defaultProps = {
  cost: "00.00",
  currency: "EUR",
};

export default Subscription;
