import { useState } from "react";
import { SUBSCRIPTION_STATUSES } from "utils/constants";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    where: {
      status: SUBSCRIPTION_STATUSES.SIGNED,
    },
  });

  const onSearch = (target) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      like: {
        ...prev.like,
        OR: [
          {
            project: {
              contact: {
                user: {
                  firstname: `%${target.value}%`,
                },
              },
            },
          },
          {
            project: {
              contact: {
                user: {
                  lastname: `%${target.value}%`,
                },
              },
            },
          },
        ],
      },
    }));
  };

  return (
    <View
      filter={filter}
      pagination={pagination}
      setPagination={setPagination}
      onSearch={onSearch}
    />
  );
};

export default List;
