import { Col, Row, Table } from "antd";
import { find, get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOpenInNew, MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS, USER_TYPES } from "utils/constants";

function List({ data, count, setValues, skip }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const LIST = t("LIST", { returnObjects: true });
  const COMPANY_FORM_OPTIONS = t("COMPANY_FORM_OPTIONS", {
    returnObjects: true,
  });
  const PROFILE_TYPES = t("PROFILE_TYPES", {
    returnObjects: true,
  });
  const columns = [
    // {
    //   title: LIST.LOGO,
    //   dataIndex: "type",
    //   render: (item) =>
    //     <img src="/favicon.ico" alt={LIST.LOGO} className="logo--table" /> || (
    //       <img src="/favicon.ico" alt={LIST.LOGO} className="logo--table" />
    //     ),
    // },
    {
      title: LIST.BUSINESS_NAME,
      dataIndex: "organization",
      render: (item) => get(item, "name", "-"),
      key: "name",
      fixed: true,
    },
    {
      title: LIST.TYPE,
      dataIndex: "organization",
      render: (item) => PROFILE_TYPES[get(item, "type", "-")],
      key: "type",
      fixed: true,
    },
    {
      title: LIST.ORIAS_CODE,
      dataIndex: "orias",
      render: (orias) => orias || "-",
    },
    {
      title: LIST.FORM_COMPANY,
      dataIndex: "organization",
      render: (item) => COMPANY_FORM_OPTIONS[get(item, "form")] || "-",
      key: "form",
    },
    {
      title: LIST.LEGAL,
      dataIndex: "organization",
      render: (item) => {
        const user = find(
          get(item, "users", []),
          (user) => get(user, "meta.type") === USER_TYPES.BROKER_ADMIN.type
        );
        if (!user) return "-";
        return `${get(LIST, `GENDER.${user.gender}`, "")} ${user.firstname} ${
          user.lastname
        }`;
      },
      key: "legal",
    },
    {
      title: LIST.PHONE,
      dataIndex: "organization",
      render: (item) =>
        find(
          get(item, "users", []),
          (user) => get(user, "meta.type") === USER_TYPES.BROKER_ADMIN.type
        )?.phone || "-",
      key: "phone",
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <Row gutter={8} justify="end" align="center" wrap={false}>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.BROKERS.DETAIL.path}/${id}`)
            }
          >
            <MdOpenInNew size={24} color={COLORS.C_PRIMARY} />
          </Col>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.BROKERS.DELETE.path}/${id}`)
            }
          >
            <MdOutlineDeleteOutline size={24} color={COLORS.C_DANGER} />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
