import { MdOutlineDescription } from "react-icons/md";

const Simple = ({ element }) => {
  return (
    <div className="__file">
      <div>
        <div>
          <MdOutlineDescription size={20} />
          {element.filename}
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default Simple;
