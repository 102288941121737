import Card from "antd/es/card/Card";
import Coverages from "entities/Coverages";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Header from "shared/components/Header";
import Search from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { COVERAGE_CATEGORIES, SCREENS } from "utils/constants";
import View from "./View";

const List = () => {
  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({});
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE);
  const navigate = useNavigate();
  const LIST = t("LIST", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const buttons = [
    {
      icon: <MdAdd size={24} />,
      text: LIST.HEADER.SAVE,
      onClick: () => navigate(SCREENS.PRIVATE.ADMIN.COVERAGES.CREATE.path),
    },
  ];

  return (
    <>
      <Header title={LIST.HEADER.TITLE} actions={buttons} />
      <Card type="simple">
        <div className="m-bottom-20 grid--4">
          <Search
            placeholder={LIST.FILTERS.SEARCH}
            type="search"
            onChange={({ target }) => {
              setPagination((prev) => ({ ...prev, skip: 0 }));
              setFilter((prev) => ({
                ...prev,
                like: {
                  OR: [
                    { identifier: `%${target.value}%` },
                    { "insurancePlan.name": `%${target.value}%` },
                  ],
                },
              }));
            }}
          />
          <Select
            options={Object.entries(COVERAGE_CATEGORIES).map(
              ([key, value]) => ({
                label: FORM.CATEGORIES[key],
                value,
              })
            )}
            allowClear
            placeholder={FORM.CATEGORY}
            onChange={(value) => {
              setPagination((prev) => ({ ...prev, skip: 0 }));
              setFilter((prev) => ({
                ...prev,
                where: {
                  ...prev.where,
                  type: value,
                },
              }));
            }}
          />
        </div>
        <Coverages
          View={View}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={View}
        />
      </Card>
    </>
  );
};

export default List;
