import cuid from "cuid";
import { Formik } from "formik";
import { find, get, isEmpty, keyBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BiCheck, BiChevronLeft, BiTrash } from "react-icons/bi";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  ATTACHMENT_TYPES,
  CONTACT_POINT_SYSTEM,
  GENDER,
  ORGANIZATION_FORMS,
  ORGANIZATION_TYPES,
  USER_TYPES,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Detail = ({ onSubmit, cancel, onDelete, data = {} }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const COMPANY_FORM_OPTIONS = t("COMPANY_FORM_OPTIONS", {
    returnObjects: true,
  });

  const telecoms = keyBy(get(data, "telecoms", []), "system");

  const handleFileUpload = async (file, setFieldValue) => {
    const { base64, ...fileData } = file;
    setFieldValue("iconAttachment", {
      ...fileData,
      id: cuid(),
      content: base64,
      type: ATTACHMENT_TYPES.ICON,
    });
    setFieldValue("icon", file.base64);
  };

  return (
    <Formik
      initialValues={{
        ...data,
        telecoms,
        user: find(
          get(data, "users", []),
          (user) => get(user, "meta.type") === USER_TYPES.INSURANCE_ADMIN.type
        ),
      }}
      onSubmit={onSubmit}
      validationSchema={validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
      )}
    >
      {({ isValid, handleSubmit, setFieldValue, ...formProps }) => [
        <Header
          key={"header"}
          title={DETAIL.HEADER.TITLE}
          actions={[
            {
              text: DETAIL.HEADER.DISCARD,
              onClick: cancel,
              type: "primary--outlined",
              icon: <BiChevronLeft size={20} />,
            },
            {
              text: DETAIL.HEADER.DELETE,
              onClick: onDelete,
              type: "danger--outlined",
              icon: <BiTrash size={20} />,
            },
            {
              text: DETAIL.HEADER.SAVE,
              onClick: handleSubmit,
              type: "primary",
              icon: <BiCheck size={20} />,
            },
          ]}
        />,
        <Card key="organization" type={"simple"} head title={FORM.ADMIN_INFO}>
          <div className="grid--2">
            <Input
              placeholder={FORM.BUSINESS_NAME}
              label={FORM.BUSINESS_NAME}
              {...bindInputProps({ name: "name", ...formProps })}
              required
            />
            <Select
              label={FORM.CATEGORY.LABEL}
              placeholder={FORM.CATEGORY.LABEL}
              options={Object.values(ORGANIZATION_TYPES).map((value) => ({
                value,
                label: FORM.CATEGORY.OPTIONS[value],
              }))}
              {...bindInputProps({ name: "type", ...formProps })}
              disabled
            />
            <Select
              label={FORM.COMPANY_FORM}
              placeholder={FORM.COMPANY_FORM}
              options={Object.values(ORGANIZATION_FORMS).map((value) => ({
                value,
                label: COMPANY_FORM_OPTIONS[value],
              }))}
              {...bindInputProps({ name: "form", ...formProps })}
            />
            <Input
              placeholder={FORM.ORGANIZATION_PHONE}
              label={FORM.ORGANIZATION_PHONE}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ORGANIZATION_EMAIL}
              label={FORM.ORGANIZATION_EMAIL}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.POSTAL_ADDRESS}
              label={FORM.POSTAL_ADDRESS}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.ZIP_CODE}
              label={FORM.ZIP_CODE}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                ...formProps,
              })}
            />
            <Input
              placeholder={FORM.CITY}
              label={FORM.CITY}
              {...bindInputProps({
                name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                ...formProps,
              })}
            />
            <Input
              type="file"
              variant="uploader"
              listType="picture"
              showUploadList={false}
              maxCount={1}
              onUpload={([file]) => handleFileUpload(file, setFieldValue)}
              fileList={
                isEmpty(get(formProps, "values.icon", []))
                  ? []
                  : [get(formProps, "values.icon")]
              }
            >
              <Button type="primary--link">{FORM.ICON}</Button>
              <div>
                {get(formProps, "values.icon") && (
                  <img
                    className="icon"
                    alt="icon"
                    src={get(formProps, "values.icon")}
                  />
                )}
              </div>
            </Input>
          </div>
        </Card>,
        <div key="org-div" className="m-bottom-24" />,
        <Card key="user" type={"simple"} head title={FORM.LEGAL_INFO}>
          <div className="grid--2">
            <Radio
              isHorizontal={false}
              label={FORM.CIVILITY.LABEL}
              options={[
                {
                  label: FORM.CIVILITY.MALE,
                  value: GENDER.MALE,
                },
                {
                  label: FORM.CIVILITY.FEMALE,
                  value: GENDER.FEMALE,
                },
              ]}
              {...bindInputProps({ name: "user.gender", ...formProps })}
              required
            />
            <Input
              placeholder={FORM.FIRST_NAME}
              label={FORM.FIRST_NAME}
              {...bindInputProps({ name: "user.firstname", ...formProps })}
              required
            />
            <Input
              placeholder={FORM.LAST_NAME}
              label={FORM.LAST_NAME}
              {...bindInputProps({ name: "user.lastname", ...formProps })}
              required
            />
            <Input
              placeholder={FORM.PHONE}
              label={FORM.PHONE}
              {...bindInputProps({ name: "user.phone", ...formProps })}
              required
            />
            <Input
              placeholder={FORM.EMAIL}
              label={FORM.EMAIL}
              {...bindInputProps({ name: "user.email", ...formProps })}
              required
            />
          </div>
        </Card>,
      ]}
    </Formik>
  );
};

export default Detail;
