import Attachments from "entities/Attachments";
import { get } from "lodash";
import { useMutation } from "shared/hooks/useApi";
import graphql from "utils/api/graphql";
import {
  CREATE_ATTACHMENT,
  GENERATE_DUTY_OF_ADVICE_DOCUMENT,
  GENERATE_ESIGN_DOCUMENTS,
  GENERATE_MEMBERSHIP_FORM_DOCUMENT,
  GENERATE_SEPA_DIRECT_DEBIT_MANDATE_DOCUMENT,
  GENERATE_TERMINATION_LETTER_DOCUMENT,
  GENERATE_TERMINATION_MANDATE_DOCUMENT,
} from "utils/api/graphql/mutations/attachment";
import {
  DOWNLOAD_DOCUMENT,
  DOWNLOAD_SINGLE_DOCUMENT,
} from "utils/api/graphql/mutations/download-document";
import { SEND_SUBSCRIPTION_ATTACHMENTS } from "utils/api/graphql/mutations/subscription";
import { INSURANCE_PLAN_ATTACHMENTS_TYPES } from "utils/constants";
import View from "./View";

const Documents = ({
  subscription,
  additionalInfoPercentage,
  setActive,
  updateSubscription,
}) => {
  const [downloadDocuments] = useMutation(DOWNLOAD_DOCUMENT);
  const [downloadSingleDocument] = useMutation(DOWNLOAD_SINGLE_DOCUMENT);
  const [generateMembershipFormDocument] = useMutation(
    GENERATE_MEMBERSHIP_FORM_DOCUMENT
  );
  const [generateSepaDirectDebitMandateDocument] = useMutation(
    GENERATE_SEPA_DIRECT_DEBIT_MANDATE_DOCUMENT
  );
  const [generateTerminationMandateDocument] = useMutation(
    GENERATE_TERMINATION_MANDATE_DOCUMENT
  );
  const [generateTerminationLetterDocument] = useMutation(
    GENERATE_TERMINATION_LETTER_DOCUMENT
  );
  const [generateDutyOfAdviceDocument] = useMutation(
    GENERATE_DUTY_OF_ADVICE_DOCUMENT
  );
  const [generateEsingDocuments] = useMutation(GENERATE_ESIGN_DOCUMENTS);
  const [sendSubscriptionAttachments] = useMutation(
    SEND_SUBSCRIPTION_ATTACHMENTS,
    {
      refetchQueries: [
        {
          query: graphql.queries.SUBSCRIPTION,
          awaitRefetchQueries: true,
          variables: { where: { id: get(subscription, "id") } },
        },
      ],
    }
  );

  const [addAttachment] = useMutation(CREATE_ATTACHMENT);

  const filter = {
    where: {
      OR: [
        {
          insurancePlan: {
            id: get(subscription, "insurancePlan.id"),
          },
        },
        {
          subscription: {
            id: get(subscription, "id"),
          },
        },
      ],
    },
    isIn: { type: Object.values(INSURANCE_PLAN_ATTACHMENTS_TYPES) },
  };

  return (
    <Attachments
      View={View}
      NoData={View}
      filter={filter}
      additionalInfoPercentage={additionalInfoPercentage}
      setActive={setActive}
      sendAttachments={sendSubscriptionAttachments}
      addAttachment={addAttachment}
      subscription={subscription}
      generateduty={generateMembershipFormDocument}
      generateDutyOfAdviceDocument={generateDutyOfAdviceDocument}
      generateMembershipFormDocument={generateMembershipFormDocument}
      generateTerminationMandateDocument={generateTerminationMandateDocument}
      generateTerminationLetterDocument={generateTerminationLetterDocument}
      generateSepaDirectDebitMandateDocument={
        generateSepaDirectDebitMandateDocument
      }
      generateEsingDocuments={generateEsingDocuments}
      downloadDocuments={downloadDocuments}
      downloadSingleDocument={downloadSingleDocument}
      updateSubscription={updateSubscription}
    />
  );
};

export default Documents;
