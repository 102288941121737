export const NAME_SPACES = {
  HOME: "home",
  GENERIC: "generic",
  PRIVATE: {
    BROKER: {
      CLIENT: "broker.client",
      CONTRACT: "broker.contract",
      ORGANIZATION: "broker.organization",
      PROJECT: "broker.project",
      USER: "broker.user",
      REMINDER: "broker.reminder",
      QUALIFICATION: "broker.qualification",
      NOTE: "broker.note",
      COLLABORATOR: "broker.collaborator",
      SUBSCRIPTION: "broker.subscription",
    },
    ADMIN: {
      SOURCE: "admin.source",
      COLLABORATOR: "admin.collaborator",
      TRACER: "admin.tracer",
      FLOW: "admin.flow",
      PROCESS: "admin.process",
      USER: "admin.user",
      INSURANCE_PLAN: "admin.insurance.plan",
      COVERAGE: "admin.coverage",
      COVERAGE_BENEFIT: "admin.coverage.benefit",
      QUALIFIER: "admin.qualifier",
      QUALIFICATION: "admin.qualification",
      WEBHOOK: "admin.webhook",
      BROKER: "admin.broker",
      WORKFLOW: "admin.workflow",
      PRICER: "admin.pricer",
      COMMISSION_STATEMENT: "admin.commission.statement",
      COMMISSION: "admin.commission",
      CATEGORIES_WEIGHT: "admin.categories.weight",
      ORGANIZATION: "admin.organization",
    },
  },
  PUBLIC: {
    CONTACT: "contact",
  },
  ERRORS: "errors",
  AUTH: "auth",
  BREADCRUMB: "breadcrumb",
  COMPONENTS: "components",
};
