import Users from "entities/Users";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Card from "shared/components/Card";
import Empty from "shared/components/Empty";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { SCREENS, USER_TYPES } from "utils/constants";
import View from "./View";

const List = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const LIST = t("LIST", { returnObjects: true });

  const onDetail = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.COLLABORATORS.DETAIL.path}/${id}`);

  const onDelete = (id) =>
    navigate(`${SCREENS.PRIVATE.BROKER.COLLABORATORS.DELETE.path}/${id}`);

  const [pagination, setPagination] = useState({});
  const [filter, setFilter] = useState({
    isIn: {
      meta: [
        { type: USER_TYPES.BROKER_EMPLOYEE.type },
        { type: USER_TYPES.BROKER_REPRESENTATIVE.type },
        { type: USER_TYPES.INSURANCE_REPRESENTATIVE.type },
      ],
    },
  });

  const onSearch = (target) => {
    setPagination((prev) => ({ ...prev, skip: 0 }));
    setFilter((prev) => ({
      ...prev,
      like: {
        ...prev.like,
        OR: [
          {
            firstname: `%${target.value}%`,
          },
          {
            lastname: `%${target.value}%`,
          },
          {
            email: `%${target.value}%`,
          },
        ],
      },
    }));
  };

  const addCollaborator = () =>
    navigate(SCREENS.PRIVATE.BROKER.COLLABORATORS.CREATE.path);

  const noData = () => (
    <Empty
      type="normal"
      label={LIST.HEADER.CREATE}
      message={LIST.EMPTY_MESSAGE}
      onClick={addCollaborator}
    />
  );

  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          {
            icon: <BiPlus size={20} />,
            text: LIST.HEADER.CREATE,
            onClick: addCollaborator,
          },
        ]}
      />
      <Card type={"simple"}>
        <div className="grid--4 m-bottom-24">
          <Input
            type="search"
            placeholder={LIST.FILTERS.SEARCH}
            onChange={({ target }) => onSearch(target)}
          />
        </div>

        <Users
          View={View}
          onDetail={onDetail}
          onDelete={onDelete}
          addCollaborator={addCollaborator}
          filter={{ ...filter, ...pagination }}
          setValues={setPagination}
          skip={pagination.skip || 0}
          NoData={noData}
        />
      </Card>
    </>
  );
};

export default List;
