import { useQuery } from "@apollo/client";
import { notification } from "antd";
import cuid from "cuid";
import { defaults, map } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "shared/components/Spin";
import { useMutation } from "shared/hooks/useApi";
import { NAME_SPACES } from "shared/locales/constants";
import graphql from "utils/api/graphql";
import { INITIAL_VALUES, ORGANIZATION_TYPES, SCREENS } from "utils/constants";
import View from "./View";

const Detail = () => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.BROKER);
  const { id } = useParams();
  const FORM = t("FORM", { returnObjects: true });
  const navigate = useNavigate();
  const cancel = () => navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST.path);

  const [updateUserAuth] = useMutation(graphql.mutations.UPDATE_USER_AUTH, {
    onError: () => notification.error({ message: FORM.IN_USE }),
  });

  const [updateUser] = useMutation(graphql.mutations.UPDATE_USER);

  const [updateOrganization] = useMutation(
    graphql.mutations.UPDATE_ORGANIZATION,
    {
      refetchQueries: [
        {
          query: graphql.queries.ORGANIZATIONS,
          awaitRefetchQueries: true,
          variables: {
            where: { type: ORGANIZATION_TYPES.INS },
            ...INITIAL_VALUES,
          },
        },
        {
          query: graphql.queries.ORGANIZATION,
          awaitRefetchQueries: true,
          variables: { where: { id } },
        },
      ],
      onCompleted: () => {
        navigate(SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.LIST.path);
      },
    }
  );

  const { data, loading, error } = useQuery(graphql.queries.ORGANIZATION, {
    variables: {
      where: { id },
    },
  });

  const onDelete = () =>
    navigate(`${SCREENS.PRIVATE.ADMIN.ORGANIZATIONS.DELETE.path}/${id}`);

  const onSubmit = ({ telecoms, iconAttachment, user, ...organization }) => {
    const organizationTelecoms = map(telecoms, (item, key) =>
      defaults(item, { id: cuid(), system: key })
    );
    updateUserAuth({
      variables: {
        where: { id: user.id },
        data: {
          username: user.email,
          email: user.email,
          phone: user.phone,
        },
      },
      onCompleted: () =>
        updateUser({
          variables: {
            where: { id: user?.id },
            data: user,
          },
          onCompleted: () => {
            updateOrganization({
              variables: {
                where: { id: organization?.id },
                data: {
                  ...organization,
                  telecoms: organizationTelecoms,
                  icon: iconAttachment,
                },
              },
            });
          },
        }),
    });
  };

  if (loading || error) return <Loading />;

  return (
    <View
      onSubmit={onSubmit}
      cancel={cancel}
      data={omitDeep(data.organization, "__typename")}
      onDelete={onDelete}
    />
  );
};

export default Detail;
