import { SCREENS } from "utils/constants";
import ForgotPassword from "./ForgotPassword";
import Logout from "./Logout";
import Redirect from "./Redirect";
import ResetPassword from "./ResetPassword";
import Signin from "./Signin";

const routes = [
  {
    path: SCREENS.AUTH.REDIRECT.path,
    exact: true,
    element: <Redirect />,
  },
  {
    path: SCREENS.AUTH.LOGOUT.path,
    exact: true,
    element: <Logout />,
  },
  {
    path: SCREENS.AUTH.SIGN_IN.path,
    exact: true,
    element: <Signin />,
  },
  {
    path: SCREENS.AUTH.RESET_PASSWORD.path,
    exact: true,
    element: <ResetPassword />,
  },
  {
    path: SCREENS.AUTH.FORGOT_PASSWORD.path,
    exact: true,
    element: <ForgotPassword />,
  },
  {
    path: "*",
    exact: false,
    element: <Redirect />,
  },
];

export default routes;
