import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import Projects from "entities/Projects";
import { get, isEmpty, set } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BiPlus } from "react-icons/bi";
import { BsViewList } from "react-icons/bs";
import { IoDuplicateOutline } from "react-icons/io5";
import {
  MdDeleteOutline,
  MdOpenInNew,
  MdOutlineEventBusy,
  MdOutlineLock,
  MdOutlineLockOpen,
} from "react-icons/md";
import { TbFileExport } from "react-icons/tb";
import Avatar from "shared/components/Avatar";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Filter from "shared/components/Filter";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Pagination from "shared/components/Pagination";
import Popover from "shared/components/Popover";
import Status from "shared/components/Status";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { ACTIONS, DATE_TIME_FORMAT, PROCESS_TAGS } from "utils/constants";
import Subscriptions from "./Subscriptions";

const ACCESOR = {
  PROCESS_TAG: "status.tag",
};

const LOCK_ICON = {
  [true]: <MdOutlineLock size={24} color={COLORS.C_GRAY} />,
  [false]: <MdOutlineLockOpen size={24} color={COLORS.C_GRAY} />,
};

const GENDER = {
  F: <WomanOutlined style={{ color: COLORS.C_FEMALE }} />,
  M: <ManOutlined style={{ color: COLORS.C_MALE }} />,
};

const List = ({
  filter,
  onSearch,
  applyFilter,
  pagination,
  setPagination,
  duplicate,
  createProject,
  onDelete,
  onDetail,
  actions,
  exportSubscriptions,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const FILTER = t("FILTER", { returnObjects: true });
  const LIST = t("LIST", { returnObjects: true });

  const [projectId, setProjectId] = useState(null);
  const showDrawerSubscription = (id) => {
    setProjectId(id);
  };
  const onCloseSubscription = () => {
    setProjectId(null);
  };

  const columns = [
    {
      width: 62,
      dataIndex: "contact",
      render: (contact) => {
        return (
          <Avatar
            mode="rand"
            size={"medium"}
            color="random"
            value={`${get(contact, "user.firstname", "")?.substring(0, 1)}${get(
              contact,
              "user.lastname",
              ""
            )?.substring(0, 1)}`}
          />
        );
      },
      fixed: true,
    },
    {
      title: LIST.CLIENT,
      dataIndex: "contact",
      render: (contact) => (
        <div>
          {GENDER[get(contact, "user.gender")]}
          {` ${get(contact, "user.firstname", "-")} ${get(
            contact,
            "user.lastname",
            ""
          )}`}
        </div>
      ),
      fixed: true,
    },
    {
      title: LIST.STATUS,
      dataIndex: "status",
      render: (record) => (
        <Status
          type="custom"
          style={{
            color: get(record, "color"),
            background: get(record, "background"),
          }}
          text={get(record, "name", "-")}
        />
      ),
    },
    {
      title: LIST.CREATION_DATE,
      dataIndex: "createdDate",
      render: (record) => moment(record).format(DATE_TIME_FORMAT),
    },
    {
      title: LIST.ADVISOR,
      dataIndex: "author",
      render: (record) =>
        `${get(record, "firstname")} ${get(record, "lastname")}`,
    },
    {
      width: 62,
      dataIndex: "locked",
      align: "right",
      render: (record, row) => (
        <div className="d-flex justify--end">
          {row.expired && (
            <div className="popover-wrapper">
              <Popover text={LIST.EXPIRED_POPOVER} />
              <MdOutlineEventBusy size={24} color={COLORS.C_DANGER} />
            </div>
          )}
          <div className="popover-wrapper">
            {record && <Popover text={LIST.LOCKED_POPOVER} />}
            {LOCK_ICON[record]}
          </div>
        </div>
      ),
    },
    ...(actions[ACTIONS.UPDATE_SUBSCRIPTION] ||
    actions[ACTIONS.DELETE_PROJECT] ||
    actions[ACTIONS.DUPLICATE_PROJECT] ||
    actions[ACTIONS.UPDATE_PROJECT]
      ? [
          {
            title: LIST.ACTIONS,
            dataIndex: "id",
            align: "right",
            render: (id, project) => (
              <div className="d-flex justify--end">
                {!isEmpty(get(project, "subscriptions", [])) &&
                  ![PROCESS_TAGS.EXPIRED, PROCESS_TAGS.SIGNED].includes(
                    get(project, "status.tag")
                  ) &&
                  actions[ACTIONS.UPDATE_SUBSCRIPTION] && (
                    <Button
                      type="primary--light"
                      icon={<BsViewList />}
                      onClick={() => showDrawerSubscription(id)}
                    >
                      {LIST.RESUME_SUBSCRIPTION}
                    </Button>
                  )}
                {actions[ACTIONS.DUPLICATE_PROJECT] && (
                  <IoDuplicateOutline
                    size={24}
                    onClick={() => {
                      duplicate({
                        variables: {
                          where: { id },
                        },
                      });
                    }}
                  />
                )}
                {actions[ACTIONS.DELETE_PROJECT] && (
                  <MdDeleteOutline
                    color={COLORS.C_DANGER}
                    size={24}
                    onClick={() => onDelete(id)}
                  />
                )}
                {actions[ACTIONS.PROJECT] &&
                  actions[ACTIONS.UPDATE_PROJECT] && (
                    <MdOpenInNew
                      color={COLORS.C_PRIMARY}
                      size={24}
                      onClick={() => onDetail(id)}
                    />
                  )}
              </div>
            ),
          },
        ]
      : []),
  ];
  return (
    <>
      <Header
        key={"header"}
        title={LIST.HEADER.TITLE}
        actions={[
          ...(actions[ACTIONS.ADD_PROJECT]
            ? [
                {
                  icon: <BiPlus size={20} />,
                  text: LIST.HEADER.CREATE.NORMAL,
                  onClick: createProject,
                },
              ]
            : []),
          ...(actions[ACTIONS.EXPORT_SUBSCRIPTIONS]
            ? [
                {
                  icon: <TbFileExport size={20} />,
                  text: LIST.HEADER.EXPORT_SUBSCRIPTIONS,
                  onClick: exportSubscriptions,
                },
              ]
            : []),
        ]}
      />
      <Card type="simple">
        <Row>
          <Col lg={20} xs={24}>
            <Row gutter={[8, 8]}>
              <Col lg={12} xs={24}>
                <Input
                  type="search"
                  placeholder={LIST.FILTERS.SEARCH}
                  onChange={({ target }) => onSearch(target)}
                />
              </Col>
              <Col lg={8} xs={24}>
                <Filter
                  translate={FILTER.PROCESS_TAG}
                  items={Object.keys(PROCESS_TAGS)}
                  setFilter={(items) =>
                    applyFilter(set({}, ACCESOR.PROCESS_TAG, items))
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <div className="m-bottom-24" />
      <Projects
        View={({ count, data, setValues, skip }) => (
          <Card type={"simple"}>
            <Table
              dataSource={data}
              columns={columns}
              className="table__wrapper"
              rowKey={"id"}
              pagination={false}
              scroll={{ x: true }}
            />
            <Pagination count={count} setValues={setValues} skip={skip || 0} />
          </Card>
        )}
        setValues={setPagination}
        skip={pagination.skip}
        filter={{ ...filter, ...pagination }}
      />
      {projectId && (
        <Subscriptions onClose={onCloseSubscription} project={projectId} />
      )}
    </>
  );
};

export default List;
