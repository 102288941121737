import { gql } from "@apollo/client";

export const SUBSCRIPTIONS = gql`
  query Subscriptions(
    $where: SubscriptionWhereInput
    $like: JSONObject
    $take: Int
    $skip: Int
  ) {
    subscriptions(where: $where, like: $like, take: $take, skip: $skip) {
      count
      data {
        id
        createdDate
        signedDate
        locked
        status
        insurancePlan {
          id
          name
          description
          icon
          params {
            id
            deltaStart
            deltaStartRIA
          }
        }
        project {
          id
          ria
          expired
          generalCosts {
            id
            cost
            currency
            commission {
              id
              type
              value
              withholding
            }
            insurancePlan {
              id
            }
          }
          contact {
            id
            user {
              id
              firstname
              lastname
            }
          }
          contract {
            id
            issuanceDate
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION = gql`
  query subscription($where: SubscriptionWhereInput) {
    subscription(where: $where) {
      id
      createdDate
      locked
      envelopeId
      status
      externalManagement
      signedDate
      additionalInfo
      attachments {
        id
        name
        type
        fileUrl
      }
      insurancePlan {
        id
        name
        fields
      }
      project {
        id
        ria
        projectType
        fields
        terminationRequest {
          id
          clientNumber
          contractNumber
          recipient
          reason
          madeIn
          address
          dueDate
          brokerageMandate {
            id
            firstName
            lastName
            currentCompany
          }
        }
        contact {
          id
          socialRegime
          socialSecurityNumber
          familySituation
          socioProfessionalCategory
          politicallyExposedPerson
          siretNumber
          profession
          exerciseFrame
          optMadelin
          acceptContact
          acceptDigital
          telecoms {
            id
            system
            value
          }
          user {
            id
            firstname
            lastname
            gender
            phone
            birthDate
          }
          relatedPersons {
            id
            relationship
            identity {
              id
              socialRegime
              socioProfessionalCategory
              socialSecurityNumber
              fields
              user {
                id
                firstname
                lastname
                birthDate
              }
            }
          }
          telecoms {
            id
            system
            value
          }
          fields
        }
        contract {
          id
          issuanceDate
          caseType
          saleMode
        }
      }
      payment {
        id
        type
        frequency
        debitDay
        payer {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
            isAdherent
            type
            socialReason
            telecoms {
              id
              system
              value
            }
          }
        }
        receiver {
          id
          BIC
          IBAN
          owner {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const subscriptions = { SUBSCRIPTIONS, SUBSCRIPTION };

export default subscriptions;
