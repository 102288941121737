import { Col, Row, Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  MdEuro,
  MdOutlineCake,
  MdOutlineChangeCircle,
  MdOutlineDateRange,
  MdOutlineHealthAndSafety,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import format from "string-template";
import {
  CASE_TYPES,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  PROJECT_TYPES,
  SALE_MODES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Contract = ({
  formProps,
  values,
  setFieldValue,
  disabled,
  setDisabled,
  setPercent,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const eSignDisabled =
    get(values, "project.contract.saleMode") === SALE_MODES.DISTANT &&
    get(values, "project.projectType") === PROJECT_TYPES.COLD_LEAD &&
    moment().diff(values.createdDate, "hours") <= 24;
  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CONTRACT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CONTRACT.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.START_DATE.PLACEHOLDER}
              type="date"
              variant="simple"
              disabled
              label={SUBSCRIPTION.FORM.START_DATE.LABEL}
              icon={<MdOutlineCake size={16} />}
              defaultValue={dayjs(get(values, "project.contract.issuanceDate"))}
              onChange={(_, value) =>
                setFieldValue("project.contract.issuanceDate", value)
              }
              onBlur={() => setPercent(values)}
              format={DATE_FORMAT}
              required={isFieldRequired("project.contract.issuanceDate")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              type="simple"
              label={SUBSCRIPTION.FORM.CASE_TYPE.LABEL}
              icon={<MdOutlineHealthAndSafety size={16} />}
              placeholder={SUBSCRIPTION.FORM.CASE_TYPE.PLACEHOLDER}
              disabled={get(disabled, "project.contract.caseType")}
              options={Object.entries(CASE_TYPES).map(([key, value]) => ({
                label: SUBSCRIPTION.FORM.CASE_TYPE.OPTIONS[key],
                value,
              }))}
              {...bindInputProps({
                name: "project.contract.caseType",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              required={isFieldRequired("project.contract.caseType")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <div className="d-flex">
              <MdOutlineChangeCircle size={16} />
              <span className="contact-stepper__content--radio--title">
                {SUBSCRIPTION.FORM.RIA.LABEL}
              </span>
              <Switch
                className="contact-stepper__content--radio--switch button__switch"
                checkedChildren={"YES"}
                unCheckedChildren={"NO"}
                disabled
                defaultChecked={get(values, "project.ria")}
              />
            </div>
          </Col>
          <Col lg={24} xs={24}>
            <Select
              type="simple"
              label={SUBSCRIPTION.FORM.SALE_MODE.LABEL}
              icon={<MdOutlineHealthAndSafety size={16} />}
              placeholder={SUBSCRIPTION.FORM.SALE_MODE.PLACEHOLDER}
              options={Object.entries(SALE_MODES).map(([key, value]) => ({
                label: SUBSCRIPTION.FORM.SALE_MODE.OPTIONS[key],
                value,
              }))}
              {...bindInputProps({
                name: "project.contract.saleMode",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              onChange={(_, { value }) => {
                setFieldValue("project.projectType", null);
                setFieldValue("project.contract.saleMode", value);
                setDisabled((prev) => ({
                  ...prev,
                  project: {
                    ...prev.project,
                    projectType: [SALE_MODES.FACE_TO_FACE].includes(value),
                  },
                }));
              }}
              required={isFieldRequired("project.contract.saleMode")}
              disabled={get(disabled, "project.contract.saleMode")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              type="simple"
              label={SUBSCRIPTION.FORM.PROJECT_TYPE.LABEL}
              icon={<MdOutlineHealthAndSafety size={16} />}
              placeholder={SUBSCRIPTION.FORM.PROJECT_TYPE.PLACEHOLDER}
              options={Object.entries(PROJECT_TYPES).map(([key, value]) => ({
                label: SUBSCRIPTION.FORM.PROJECT_TYPE.OPTIONS[key],
                value,
              }))}
              {...bindInputProps({
                name: "project.projectType",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              disabled={get(disabled, "project.projectType")}
              required={isFieldRequired("project.projectType")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.QUOTE_RECEIVED_DATE.PLACEHOLDER}
              type="date"
              variant="simple"
              disabled
              label={SUBSCRIPTION.FORM.QUOTE_RECEIVED_DATE.LABEL}
              icon={<MdOutlineDateRange size={16} />}
              defaultValue={dayjs(get(values, "createdDate"))}
              onChange={(record, date) => {
                setFieldValue("createdDate", record);
              }}
              onBlur={() => setPercent(values)}
              format={DATE_TIME_FORMAT}
              required={isFieldRequired("createdDate")}
            />
            {eSignDisabled && (
              <span className="warning-message">
                {format(SUBSCRIPTION.ADDITIONAL_INFORMATION.E_SIGN, {
                  time: moment(values.createdDate)
                    .add(24, "hours")
                    .format(DATE_TIME_FORMAT),
                })}
              </span>
            )}
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.BROKERAGE_FEES.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.BROKERAGE_FEES.LABEL}
              icon={<MdEuro size={16} />}
              type="number"
              {...bindInputProps({
                name: "additionalInfo.brokerageFees",
                values,
                ...formProps,
              })}
              required={isFieldRequired("additionalInfo.brokerageFees")}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Contract;
