import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { NAME_SPACES } from "shared/locales/constants";
import Popover from "../Popover";

const Label = ({ label, icon, required }) => {
  const { t } = useTranslation(NAME_SPACES.ERRORS);
  const VALIDATIONS = t("VALIDATIONS", { returnObjects: true });
  return (
    <div className={classNames({ label__area: label, "hide--label": !label })}>
      {icon && (
        <span className={classNames("label__icon", { "hide--icon": !icon })}>
          {icon}
        </span>
      )}
      <label htmlFor={label}>{label}</label>
      {required && (
        <div className="popover-wrapper">
          <Popover text={VALIDATIONS.REQUIRED} />
          <span className="label__required">*</span>
        </div>
      )}
    </div>
  );
};

Label.propTypes = {
  label: PropTypes.any,
  className: PropTypes.string,
  icon: PropTypes.node,
};

Label.defaultProps = {
  setType: () => {},
};

export default Label;
