export const SCREENS = {
  PRIVATE: {
    BROKER: {
      INDEX: { path: "/broker", breadcrumb: [] },
      CONTRACTS: {
        LIST: { path: "/broker/contracts", breadcrumb: [] },
        CREATE: { path: "/broker/contracts/create", breadcrumb: [] },
        DETAIL: { path: "/broker/contracts/detail", breadcrumb: [] },
        DELETE: { path: "/broker/contracts/delete", breadcrumb: [] },
      },
      CLIENTS: {
        LIST: { path: "/broker/clients", breadcrumb: [] },
        CREATE: { path: "/broker/clients/create", breadcrumb: [] },
        DETAIL: { path: "/broker/clients/detail", breadcrumb: [] },
      },
      QUALIFICATIONS: {
        LIST: { path: "/broker/qualifications", breadcrumb: [] },
        CREATE: { path: "/broker/qualifications/create", breadcrumb: [] },
        SHOW: { path: "/broker/qualifications/show", breadcrumb: [] },
      },
      NOTES: {
        LIST: { path: "/broker/notes", breadcrumb: [] },
        CREATE: { path: "/broker/notes/create", breadcrumb: [] },
        DELETE: { path: "/broker/notes/delete", breadcrumb: [] },
      },
      ORGANIZATIONS: {
        LIST: { path: "/broker/organizations", breadcrumb: [] },
        CREATE: { path: "/broker/organizations/create", breadcrumb: [] },
        DETAIL: { path: "/broker/organizations/detail", breadcrumb: [] },
        DELETE: { path: "/broker/organizations/delete", breadcrumb: [] },
      },
      PROJECTS: {
        LIST: { path: "/broker/projects", breadcrumb: [] },
        CREATE: {
          NORMAL: { path: "/broker/projects/create/normal", breadcrumb: [] },
          FAST: { path: "/broker/projects/create/fast", breadcrumb: [] },
        },
        DETAIL: {
          path: "/broker/projects/detail",
          breadcrumb: [
            { label: "PRIVATE.BROKER.DASHBOARD", to: "" },
            { label: "PRIVATE.BROKER.PROJECT.CREATE", to: "" },
            { label: "PRIVATE.BROKER.PROJECT.DETAIL", to: "" },
          ],
        },
        DELETE: { path: "/broker/projects/delete", breadcrumb: [] },
        TRACERS: {
          IDENTIFY: {
            path: "/broker/projects/tracers/identify",
            breadcrumb: [],
          },
        },
      },
      USERS: {
        LIST: { path: "/broker/users", breadcrumb: [] },
        CREATE: { path: "/broker/users/create", breadcrumb: [] },
        DETAIL: { path: "/broker/users/detail", breadcrumb: [] },
        DELETE: { path: "/broker/users/delete", breadcrumb: [] },
      },
      REMINDERS: {
        LIST: { path: "/broker/reminders", breadcrumb: [] },
        CREATE: { path: "/broker/reminders/create", breadcrumb: [] },
      },
      SUBSCRIPTIONS: {
        DETAIL: { path: "/broker/subscriptions/detail", breadcrumb: [] },
      },
      COLLABORATORS: {
        LIST: { path: "/broker/collaborators", breadcrumb: [] },
        CREATE: { path: "/broker/collaborators/create", breadcrumb: [] },
        DETAIL: { path: "/broker/collaborators/detail", breadcrumb: [] },
        DELETE: { path: "/broker/collaborators/delete", breadcrumb: [] },
      },
      COMMISSION_STATEMENTS: {
        LIST: { path: "/broker/commissions-statements", breadcrumb: [] },
      },
    },
    ADMIN: {
      INDEX: { path: "/admin", breadcrumb: [] },
      PROCESSES: {
        LIST: { path: "/admin/processes", breadcrumb: [] },
        CREATE: { path: "/admin/processes/create", breadcrumb: [] },
        DETAIL: { path: "/admin/processes/detail", breadcrumb: [] },
        DELETE: { path: "/admin/processes/delete", breadcrumb: [] },
      },
      FLOWS: {
        LIST: { path: "/admin/flows", breadcrumb: [] },
        CREATE: { path: "/admin/flows/create", breadcrumb: [] },
        DETAIL: { path: "/admin/flows/detail", breadcrumb: [] },
        DELETE: { path: "/admin/flows/delete", breadcrumb: [] },
      },
      TRACERS: {
        LIST: { path: "/admin/tracers", breadcrumb: [] },
        CREATE: { path: "/admin/tracers/create", breadcrumb: [] },
        DETAIL: { path: "/admin/tracers/detail", breadcrumb: [] },
        DELETE: { path: "/admin/tracers/delete", breadcrumb: [] },
      },
      SOURCES: {
        LIST: { path: "/admin/sources", breadcrumb: [] },
        CREATE: { path: "/admin/sources/create", breadcrumb: [] },
        DETAIL: { path: "/admin/sources/detail", breadcrumb: [] },
        DELETE: { path: "/admin/sources/delete", breadcrumb: [] },
      },
      USERS: {
        LIST: { path: "/admin/users", breadcrumb: [] },
        CREATE: { path: "/admin/users/create", breadcrumb: [] },
        DETAIL: { path: "/admin/users/detail", breadcrumb: [] },
        DELETE: { path: "/admin/users/delete", breadcrumb: [] },
      },
      BROKERS: {
        LIST: { path: "/admin/brokers", breadcrumb: [] },
        CREATE: { path: "/admin/brokers/create", breadcrumb: [] },
        DETAIL: { path: "/admin/brokers/detail", breadcrumb: [] },
        DELETE: { path: "/admin/brokers/delete", breadcrumb: [] },
      },
      ORGANIZATIONS: {
        LIST: { path: "/admin/organizations", breadcrumb: [] },
        CREATE: { path: "/admin/organizations/create", breadcrumb: [] },
        DETAIL: { path: "/admin/organizations/detail", breadcrumb: [] },
        DELETE: { path: "/admin/organizations/delete", breadcrumb: [] },
      },
      INSURANCE_PLANS: {
        LIST: { path: "/admin/insurance-plans", breadcrumb: [] },
        CREATE: { path: "/admin/insurance-plans/create", breadcrumb: [] },
        DETAIL: { path: "/admin/insurance-plans/detail", breadcrumb: [] },
        DELETE: { path: "/admin/insurance-plans/delete", breadcrumb: [] },
        EDIT: { path: "/admin/insurance-plans/edit", breadcrumb: [] },
      },
      COVERAGES: {
        LIST: { path: "/admin/coverages", breadcrumb: [] },
        CREATE: { path: "/admin/coverages/create", breadcrumb: [] },
        DETAIL: { path: "/admin/coverages/detail", breadcrumb: [] },
        DELETE: { path: "/admin/coverages/delete", breadcrumb: [] },
      },
      COVERAGE_BENEFITS: {
        LIST: { path: "/admin/coverage-benefits", breadcrumb: [] },
        CREATE: { path: "/admin/coverage-benefits/create", breadcrumb: [] },
        DETAIL: { path: "/admin/coverage-benefits/detail", breadcrumb: [] },
        DELETE: { path: "/admin/coverage-benefits/delete", breadcrumb: [] },
      },
      QUALIFIERS: {
        LIST: { path: "/admin/qualifiers", breadcrumb: [] },
        CREATE: { path: "/admin/qualifiers/create", breadcrumb: [] },
        DETAIL: { path: "/admin/qualifiers/detail", breadcrumb: [] },
        DELETE: { path: "/admin/qualifiers/delete", breadcrumb: [] },
      },
      QUALIFICATIONS: {
        LIST: { path: "/admin/qualifications", breadcrumb: [] },
        CREATE: { path: "/admin/qualifications/create", breadcrumb: [] },
        SHOW: { path: "/admin/qualifications/show", breadcrumb: [] },
      },
      WEBHOOKS: {
        LIST: { path: "/admin/webhooks", breadcrumb: [] },
      },
      CATEGORIES_WEIGHT: {
        LIST: { path: "/admin/categories", breadcrumb: [] },
      },
      UI: {
        LIST: { path: "/admin/ui", breadcrumb: [] },
      },
      WORKFLOWS: {
        LIST: { path: "/admin/workflows", breadcrumb: [] },
        CREATE: { path: "/admin/workflows/create", breadcrumb: [] },
        DETAIL: { path: "/admin/workflows/detail", breadcrumb: [] },
        DELETE: { path: "/admin/workflows/delete", breadcrumb: [] },
      },
      PRICERS: {
        LIST: { path: "/admin/prices", breadcrumb: [] },
        CREATE: { path: "/admin/prices/create", breadcrumb: [] },
        DETAIL: { path: "/admin/prices/detail", breadcrumb: [] },
        DELETE: { path: "/admin/prices/delete", breadcrumb: [] },
      },
      COMMISSION_STATEMENTS: {
        LIST: { path: "/admin/commissions-statements", breadcrumb: [] },
        CREATE: {
          path: "/admin/commissions-statements/create",
          breadcrumb: [],
        },
        DELETE: {
          path: "/admin/commissions-statements/delete",
          breadcrumb: [],
        },
      },
      COMMISSIONS: {
        LIST: { path: "/admin/commissions", breadcrumb: [] },
        CREATE: { path: "/admin/commissions/create", breadcrumb: [] },
        DETAIL: { path: "/admin/commissions/detail", breadcrumb: [] },
        DELETE: { path: "/admin/commissions/delete", breadcrumb: [] },
      },
    },
    EXCHANGE: { path: "/exchange", breadcrumb: [] },
  },
  AUTH: {
    REDIRECT: { path: "/auth/redirect", breadcrumb: [] },
    LOGOUT: { path: "/auth/logout", breadcrumb: [] },
    SIGN_IN: { path: "/", breadcrumb: [] },
    RESET_PASSWORD: { path: "/auth/password/passcode", breadcrumb: [] },
    FORGOT_PASSWORD: { path: "/auth/password/forgot", breadcrumb: [] },
  },
  PUBLIC: {
    INDEX: { path: "/", breadcrumb: [] },
    CONTACTS: {
      INDEX: { path: "/contacts", breadcrumb: [] },
    },
    NOTES: {
      CREATE: { path: "/notes/create", breadcrumb: [] },
    },
    QUOTES: {
      DETAIL: { path: "/quotes", breadcrumb: [] },
    },
  },
};

export const CONTRACT_TYPES = {
  PRIVACY: "PRIVACY",
  DISCLOSURE: "DISCLOSURE",
  HEALTHINSURANCE: "HEALTHINSURANCE",
  SUPPLY: "SUPPLY",
  CONSENT: "CONSENT",
};

export const CONTRACT_SUBTYPES = ["DISCLOSURE_CA", "DISCLOSURE_US"];

export const CONTRACT_CONTENT_DERIVATIVE = [
  "REGISTRATION",
  "RETRIEVAL",
  "STATEMENT",
  "SHAREABLE",
];

export const CONTRACT_EXPIRATION_TYPES = ["BREACH"];

export const CONTRACT_SCOPE = ["POLICY"];

export const ORGANIZATION_TYPE = [
  "PROV",
  "DEPT",
  "TEAM",
  "GOVT",
  "INS",
  "PAY",
  "EDU",
  "RELI",
  "CRS",
  "CG",
  "BUS",
  "BROKER",
  "OTHER",
];

export const KEYS = {
  ACCESS_TOKEN: "accessToken",
  USER: "user",
  LANGUAGE: "language",
};

export const USER_TYPES = {
  ADMIN: {
    type: "admin",
    index: SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path,
  },
  INSURANCE_ADMIN: {
    type: "admin.insurance",
    index: SCREENS.PRIVATE.ADMIN.INSURANCE_PLANS.LIST.path,
  },
  INSURANCE_EMPLOYEE: {
    type: "employee.insurance",
    index: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
  },
  INSURANCE_REPRESENTATIVE: {
    type: "representative.insurance",
    index: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
  },
  BROKER_ADMIN: {
    type: "admin.broker",
    index: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
  },
  BROKER_EMPLOYEE: {
    type: "employee.broker",
    index: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
  },
  BROKER_REPRESENTATIVE: {
    type: "representative.broker",
    index: SCREENS.PRIVATE.BROKER.PROJECTS.LIST.path,
  },
};

export const MODULES = {
  SOURCE: "SOURCE",
  TRACER: "TRACER",
  FLOW: "FLOW",
  USER: "USER",
  PROCESS: "PROCESS",
  PROCESS_STATUS: "PROCESS_STATUS",
  INSURANCE_PLAN: "INSURANCE_PLAN",
  COVERAGE: "COVERAGE",
  COVERAGE_BENEFIT: "COVERAGE_BENEFIT",
  SUBSCRIPTION: "SUBSCRIPTION",
  ORGANIZATION: "ORGANIZATION",
  QUALIFICATION: "QUALIFICATION",
  QUALIFIER: "QUALIFIER",
  WEBHOOK: "WEBHOOK",
  COLLABORATORS: "COLLABORATORS",
  PROJECT: "PROJECT",
  GENERAL_COST: "GENERAL_COST",
  CONTRACT: "CONTRACT",
  CONTACT: "CONTACT",
  REMINDER: "REMINDER",
  NOTE: "NOTE",
  BROKER: "BROKER",
  WORKFLOW: "WORKFLOW",
  PRICER: "PRICER",
  COMMISSION_STATEMENT: "COMMISSION_STATEMENT",
  COMMISSION: "COMMISSION",
};

export const SUBMODULES = {
  ADMIN_LIST: "ADMIN_LIST",
  ADMIN_CREATE: "ADMIN_CREATE",
  ADMIN_DETAIL: "ADMIN_DETAIL",
  ADMIN_EDIT: "ADMIN_EDIT",
  ADMIN_DELETE: "ADMIN_DELETE",
  ADMIN_SHOW: "ADMIN_SHOW",
  BROKER_LIST: "BROKER_LIST",
  BROKER_CREATE: "BROKER_CREATE",
  BROKER_DETAIL: "BROKER_DETAIL",
  BROKER_DELETE: "BROKER_DELETE",
  BROKER_IDENTIFY: "BROKER_IDENTIFY",
  BROKER_SHOW: "BROKER_SHOW",
};

export const ACTIONS = {
  SOURCES: "sources",
  DELETE_SOURCE: "deleteSource",
  ADD_SOURCE: "addSource",
  SOURCE: "source",
  UPDATE_SOURCE: "updateSource",
  TRACERS: "tracers",
  DELETE_TRACER: "deleteTracer",
  ADD_TRACER: "addTracer",
  TRACER: "tracer",
  UPDATE_TRACER: "updateTracer",
  FLOWS: "flows",
  DELETE_FLOW: "deleteFlow",
  ADD_FLOW: "addFlow",
  FLOW: "flow",
  UPDATE_FLOW: "updateFlow",
  USERS: "users",
  DELETE_USER: "deleteUser",
  ADD_USER: "addUser",
  USER: "user",
  UPDATE_USER: "updateUser",
  PROCESSES: "processes",
  DELETE_PROCESS: "deleteProcess",
  ADD_PROCESS: "addProcess",
  PROCESS: "process",
  UPDATE_PROCESS: "updateProcess",
  DELETE_COVERAGE: "deleteCoverage",
  ADD_COVERAGE: "addCoverage",
  COVERAGES: "coverages",
  COVERAGE: "coverage",
  UPDATE_COVERAGE: "updateCoverage",
  COVERAGE_BENEFITS: "coverageBenefits",
  DELETE_COVERAGE_BENEFIT: "deleteCoverageBenefit",
  ADD_COVERAGE_BENEFIT: "addCoverageBenefit",
  COVERAGE_BENEFIT: "coverageBenefit",
  UPDATE_COVERAGE_BENEFIT: "updateCoverageBenefit",
  SUBSCRIPTION: "subscription",
  UPDATE_SUBSCRIPTION: "updateSubscription",
  SEND_SUBSCRIPTION_ATTACHMENTS: "sendSubscriptionAttachments",
  DOWNLOAD_SUBSCRIPTION_ATTACHMENTS: "downloadSubscriptionAttachments",
  EXPORT_SUBSCRIPTIONS: "exportSubscriptions",
  QUALIFICATIONS: "qualifications",
  DELETE_QUALIFICATION: "deleteQualification",
  ADD_QUALIFICATION: "addQualification",
  QUALIFICATION: "qualification",
  UPDATE_QUALIFICATION: "updateQualification",
  ORGANIZATIONS: "organizations",
  DELETE_ORGANIZATION: "deleteOrganization",
  ADD_ORGANIZATION: "addOrganization",
  ORGANIZATION: "organization",
  UPDATE_ORGANIZATION: "updateOrganization",
  NOTES: "notes",
  ADD_NOTE: "addNote",
  QUALIFIERS: "qualifiers",
  DELETE_QUALIFIER: "deleteQualifier",
  ADD_QUALIFIER: "addQualifier",
  QUALIFIER: "qualifier",
  UPDATE_QUALIFIER: "updateQualifier",
  WEBHOOKS: "webhooks",
  DUPLICATE_PROJECT: "duplicateProject",
  DELETE_PROJECT: "deleteProject",
  ADD_PROJECT: "addProject",
  PROJECT: "project",
  PROJECTS: "projects",
  UPDATE_PROJECT: "updateProject",
  GENERAL_COSTS: "generalCosts",
  CONTRACTS: "contracts",
  DELETE_CONTRACT: "deleteContract",
  ADD_CONTRACT: "addContract",
  CONTRACT: "contract",
  UPDATE_CONTRACT: "updateContract",
  CONTACTS: "contacts",
  DELETE_CONTACT: "deleteContact",
  ADD_CONTACT: "addContact",
  CONTACT: "contact",
  UPDATE_CONTACT: "updateContact",
  REMINDERS: "reminders",
  ADD_REMINDER: "addReminder",
  INSURANCE_PLANS: "insurancePlans",
  INSURANCE_PLAN: "insurancePlan",
  ADD_INSURANCE_PLAN: "addInsurancePlan",
  UPDATE_INSURANCE_PLAN: "updateInsurancePlan",
  DELETE_INSURANCE_PLAN: "deleteInsurancePlan",
  WORKFLOW: "workflow",
  WORKFLOWS: "workflows",
  ADD_WORKFLOW: "addWorkflow",
  UPDATE_WORKFLOW: "updateWorkflow",
  DELETE_WORKFLOW: "deleteWorkflow",
  PRICER: "pricer",
  PRICERS: "pricers",
  ADD_PRICER: "addPricer",
  UPDATE_PRICER: "updatePricer",
  DELETE_PRICER: "deletePricer",
  COMMISSION_STATEMENT: "commissionStatement",
  COMMISSION_STATEMENTS: "commissionStatements",
  ADD_COMMISSION_STATEMENT: "addCommissionStatement",
  UPDATE_COMMISSION_STATEMENT: "updateCommissionStatement",
  DELETE_COMMISSION_STATEMENT: "deleteCommissionStatement",
  DOWNLOAD_SINGLE_DOCUMENT: "downloadSingleDocument",
};

export const TEMPLATES = {
  CONTACT: "CONTACT",
  PRODUCT_NO_LEVELS: "PRODUCT.NO.LEVELS",
  QUOTE: "QUOTE",
  SUBSCRIPTION: "SUBSCRIPTION",
  CONTRACT: "CONTRACT",
  FAST: "FAST",
  COLLECTION_NEEDS: "COLLECTION_NEEDS",
  SELECT_PRODUCTS: "SELECT_PRODUCTS",
  COMPARISON: "COMPARISON",
};

export const CONTACT_TYPES = {
  NEW: "NEW",
  NO_ANSWER: "NO_ANSWER",
  WRONG_NUMBER: "WRONG_NUMBER",
  WON: "WON",
  DUPLICATED: "DUPLICATED",
  QUOTE_SENT: "QUOTE_SENT",
};

export const MOMENT_UNITS_IN_MINUTES = {
  ONE_HOUR: 60,
};

export const COVERAGE_CATEGORIES = {
  HOSPITALIZATION: "HOSPITALIZATION",
  MATERNITY: "MATERNITY",
  DENTAL: "DENTAL",
  MEDICAL_CARE: "MEDICAL_CARE",
  EQUIPMENT: "EQUIPMENT",
  ALTERNATIVE_MEDICINE: "ALTERNATIVE_MEDICINE",
  PREVENTION: "PREVENTION",
  OTHER: "OTHER",
  OPTICAL: "OPTICAL",
};

export const COVERAGE_NEEDS = {
  HOSPITALIZATION: "hospitalization",
  DENTAL: "dental",
  OPTICAL: "optical",
  MEDICAL_CARE: "medicalExpenses",
  ALTERNATIVE_MEDICINE: "alternativeMedicine",
  EQUIPMENT: "hearingAid",
};

export const SUPPORTING_INFORMATION_CATEGORIES = {
  BASE_REFUND: "BASE_REFUND",
  CONTEXT: "CONTEXT",
  PRACTICAL_CASES: "PRACTICAL_CASES",
  REINFORCEMENT: "REINFORCEMENT",
  DESCRIPTION: "DESCRIPTION",
  BONUS: "BONUS",
};

export const DEFAUlT_PAGINATION = {
  take: 3,
  skip: 0,
};

export const CURRENCY_SYMBOL = {
  EUR: "€",
  USD: "$",
  DEFAULT: "",
};

export const COVERAGE_LIMIT_CATEGORIES = {
  COST: "COST",
  COST_PER_YEAR: "COST_PER_YEAR",
  BASE_REFUND: "BASE_REFUND",
  REAL_COSTS: "REAL_COSTS",
  INTEGRAL_RBT: "INTEGRAL_RBT",
  TEXT: "TEXT",
};

export const SUBSCRIPTION_FORM_KEYS = {
  ADDITIONAL_INFORMATION: {
    ADHERENT: "ADHERENT",
    ACTIVITY: "ACTIVITY",
    CONTACT: "CONTACT",
    PARTNER: "PARTNER",
    CHILDREN: "CHILDREN",
    PAYMENT: "PAYMENT",
    REFUND: "REFUND",
    CONTRACT: "CONTRACT",
    TERMINATION_REQUEST: "TERMINATION_REQUEST",
    BROKERAGE_MANDATE: "BROKERAGE_MANDATE",
    INFORMATION: "INFORMATION",
  },
  PROOF: {
    SOCIAL_SECURITY_CERTIFICATE: "SOCIAL_SECURITY_CERTIFICATE",
    ID: "ID",
    BANK_ACCOUNT: "BANK_ACCOUNT",
    KBIS: "KBIS",
  },
  SIGNATURE: {
    E_SIGNATURE: "E_SIGNATURE",
    DOCUMENTS: "DOCUMENTS",
  },
};

export const GENDER = {
  MALE: "M",
  FEMALE: "F",
};

export const RELATIONSHIP = {
  CHILD: "CHILD",
  SPOUSE: "SPOUSE",
};

export const BOOLEAN = {
  true: true,
  false: false,
};

export const ORGANIZATION_TYPES = {
  PROV: "PROV",
  DEPT: "DEPT",
  TEAM: "TEAM",
  GOVT: "GOVT",
  INS: "INS",
  PAY: "PAY",
  EDU: "EDU",
  RELI: "RELI",
  CRS: "CRS",
  CG: "CG",
  BUS: "BUS",
  BROKER: "BROKER",
  OTHER: "OTHER",
};

export const ORGANIZATION_FORMS = {
  PROFESSIONAL_CIVIL_COMPANY: "PROFESSIONAL_CIVIL_COMPANY",
  GIE: "GIE",
  LIMITED_PARTNERSHIP: "LIMITED_PARTNERSHIP",
  SOLE_PROPRIETORSHIP: "SOLE_PROPRIETORSHIP",
  SIMPLIFIED_JOINT_STOCK_COMPANY: "SIMPLIFIED_JOINT_STOCK_COMPANY",
  GENERAL_PARTNERSHIP: "GENERAL_PARTNERSHIP",
  EURL: "EURL",
  SELCA: "SELCA",
  SELAFA: "SELAFA",
  PUBLIC_LIMITED_COMPANY: "PUBLIC_LIMITED_COMPANY",
  SARL: "SARL",
  LIMITED_PARTNERSHIP_WITH_SHARES: "LIMITED_PARTNERSHIP_WITH_SHARES",
  PROFESSIONAL_LIBERAL_COMPANY: "PROFESSIONAL_LIBERAL_COMPANY",
  SINGLE_MEMBER_JOINT_STOCK_COMPANY: "SINGLE_MEMBER_JOINT_STOCK_COMPANY",
  SELARL: "SELARL",
  CIVIL_COMPANY_FOR_SHARED_PROFESSIONAL_SERVICES:
    "CIVIL_COMPANY_FOR_SHARED_PROFESSIONAL_SERVICES",
  SINGLE_MEMBER_LIBERAL_COMPANY: "SINGLE_MEMBER_LIBERAL_COMPANY",
  PARTNERSHIP: "PARTNERSHIP",
};

export const DATE_FORMAT = "DD/MM/YYYY";
export const API_DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

export const RISKS = [
  "2-WHEELERS",
  "CROP_INSURANCE",
  "ALL-RISKS_CONSTRUCTION_INSURANCE",
  "SCOOTER_INSURANCE",
  "BIKE_INSURANCE",
  "LIFE_INSURANCE",
  "CAR",
  "OTHER",
  "BOATS",
  "MACHINE_BREAKDOWN",
  "CARAVANS",
  "CREDIT",
  "CYBER_RISKS",
  "DEATH",
  "TAX_DEFERRAL",
  "DEPENDENCE",
  "WORKS_DAMAGE_INSURANCE",
  "BORROWER",
  "CAR_FLEET",
  "FUNERAL_EXPENSES",
  "OVERHEADS",
  "FINANCIAL_GUARANTEE_FOR_COMPLETION",
  "HEALTH_CASH_PLAN",
  "KEY_MAN",
  "GROUP_INSURANCE_FOR_SMALL_BUSINESSES",
  "BUILDING",
  "REAL_ESTATE_INVESTMENT",
  "UNPAID_RENT / RENTAL_RISK",
  "TRANSPORTED_GOODS",
  "HR",
  "PROFESSIONAL_LIABILITY_INSURANCE",
  "ART_OBJECTS_AND_COLLECTIONS",
  "PERSONAL_PENSION_ACCOUNT",
  "EMPLOYEE_SAVINGS_PLAN",
  "INDIVIDUAL_RETIREMENT_PLAN",
  "BUSINESS_INTERRUPTION",
  "GROUP_INSURANCE_FOR_NON-PROFIT_ORGANIZATIONS",
  "GROUP_INSURANCE",
  "SELF-EMPLOYED_INSURANCE",
  "LEGAL_PROTECTION_FOR_PRIVATE_LIFE",
  "LEGAL_PROTECTION_FOR_PROFESSIONALS",
  "FRANCHISE_BUYBACK",
  "HUNTING_LIABILITY_INSURANCE",
  "DECENNIAL_LIABILITY_INSURANCE",
  "MEDICAL_LIABILITY_INSURANCE",
  "ORGANIZATION_LIABILITY_INSURANCE",
  "PROFESSIONAL_LIABILITY_INSURANCE",
  "PRIVATE_LIFE",
  "EDUCATION_ANNUITY",
  "LIFE_ANNUITY",
  "DIRECTORS'_LIABILITY",
  "RETIREMENT_PLAN_83",
  "MADELIN_RETIREMENT_PLAN",
  "HEALTH_INSURANCE_FOR_ACTIVE_PEOPLE",
  "ANIMAL_HEALTH_INSURANCE",
  "GROUP_HEALTH_INSURANCE",
  "HEALTH_INSURANCE_FOR_SENIORS",
  "HEALTH_INSURANCE_FOR_THE_SELF-EMPLOYED",
  "SCHOOL_INSURANCE",
  "TRAVEL_INSURANCE",
];

export const CLIENT_CATEGORY = {
  PARTICULAR: "PARTICULAR",
  PROFESSIONAL: "PROFESSIONAL",
};

export const CLIENT_STATUS = {
  LEAD: "LEAD",
  OLD_CLIENT: "OLD_CLIENT",
  CLIENT: "CLIENT",
};
export const ATTACHMENT_STATUSES = {
  CRYPTED: "crypted",
  DECRYPTED: "decrypted",
};

export const INITIAL_VALUES = {
  take: 10,
  skip: 0,
};

export const MAX_FEES_VALUE = 50;

export const CONTACT_POINT_SYSTEM = {
  PHONE: "PHONE",
  LANDLINE: "LANDLINE",
  COMPLEMENT: "COMPLEMENT",
  FAX: "FAX",
  EMAIL: "EMAIL",
  ADDRESS_NUMBER: "ADDRESS_NUMBER",
  REPETITION_INDEX: "REPETITION_INDEX",
  STREET: "STREET",
  STREET_TYPE: "STREET_TYPE",
  ZIP_CODE: "ZIP_CODE",
  CITY: "CITY",
  ADDRESS: "ADDRESS",
};

export const NAVIGATION_DIRECTIONS = {
  BACK: -1,
};

export const USERS = {
  ADMIN: "ADMIN",
  REGULAR: "REGULAR",
  BENEFICIARY: "BENEFICIARY",
  ADHERENT: "ADHERENT",
  BROKER: "BROKER",
  REPRESENTATIVE: "REPRESENTATIVE",
  COMMERCIAL: "COMMERCIAL",
};

export const TRACER_TYPES = {
  MANUAL: "manual",
  FAST: "fast",
  COMPARISON: "comparison",
};

export const INPUT_TYPES = {
  TEXT: "text",
  SELECT: "select",
  DATE: "date",
};

export const QUALIFIER_TYPES = {
  UNTREATED: "UNTREATED",
  ARGUED: "ARGUED",
  NON_ARGUED: "NON_ARGUED",
  WRONG_NUMBER: "WRONG_NUMBER",
  DUPLICATE: "DUPLICATE",
  NO_ANSWER: "NO_ANSWER",
  REFUSAL_ANSWER: "REFUSAL_ANSWER",
  ANSWERING_MACHINE: "ANSWERING_MACHINE",
  QUOTE_SENT: "QUOTE_SENT",
  SIGNED: "SIGNED",
};

export const SOURCE_TYPES = {
  MANUAL: "manual",
};

export const SOCIAL_REGIMES = {
  SECURITE_SOCIALE: "SECURITE_SOCIALE",
  ALSACE_MOSELLE: "ALSACE_MOSELLE",
  REGIME_SPECIAL: "REGIME_SPECIAL",
  REGIME_SALARIES_AGRICOLES: "REGIME_SALARIES_AGRICOLES",
  SECURITE_SOCIALE_INDEPENDANTS: "SECURITE_SOCIALE_INDEPENDANTS",
};

export const PRICER_KEYS = {
  API: {
    ALPTIS: "api.alptis",
  },
  MOCK: "mock",
  DB: "db",
};

export const PRICER_TYPES = {
  ALPTIS: "api.alptis",
  MOCK: "mock",
  DB: "db",
};

export const STATUS = ["default", "green", "danger", "warning", "info", "red"];
export const DEFAULT_STATUS = "default";

export const TYPES = {
  DEFAULT: "default",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  DANGER: "danger",
};

export const COMPONENTS_SIZES = {
  SMALL: "small",
  LARGE: "large",
  MEDIUM: "medium",
  X_LARGE: "xLarge",
};

export const CASE_TYPES = {
  NEW_CONTRACT: "NEW_CONTRACT",
  RETURN_TO_COMPETITION: "RETURN_TO_COMPETITION",
};

export const SALE_MODES = {
  FACE_TO_FACE: "FACE_TO_FACE",
  DISTANT: "DISTANT",
};

export const PROJECT_TYPES = {
  HOT_LEAD: "HOT",
  COLD_LEAD: "COLD",
};

export const PROCESS_TAGS = {
  IN_PROGRESS: "IN_PROGRESS",
  CURRENT_SUBSCRIPTION: "CURRENT_SUBSCRIPTION",
  ELECTRONIC_SIGNATURE_PENDING: "ELECTRONIC_SIGNATURE_PENDING",
  SIGNED: "SIGNED",
  EXPIRED: "EXPIRED",
  QUOTE_SENT: "QUOTE_SENT",
};

export const DELTA_END_TYPES = {
  ADD_PERIOD: "ADD_PERIOD",
  END_OF_YEAR: "END_OF_YEAR",
};

export const PERIOD_UNITS = {
  DAYS: "days",
  MONTHS: "months",
  YEARS: "years",
};

export const COMMISSION_TYPES = {
  LINEAR: "LINEAR",
  WITHHOLDING: "WITHHOLDING",
};

export const ACTION_TYPES = {
  NAVIGATE: "NAVIGATE",
  WEBHOOK: "WEBHOOK",
};

export const FAMILY_SITUATION = {
  MARRIED: "MARRIED",
  DIVORCED: "DIVORCED",
  SEPARATED: "SEPARATED",
  SINGLE: "SINGLE",
  WIDOWED: "WIDOWED",
  PACS: "PACS",
  COHABITING: "COHABITING",
};

export const PAYMENT_TYPES = {
  DEBIT: "DEBIT",
  TIP: "TIP",
};

export const PAYMENT_FREQUENCIES = {
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
  BIANNUAL: "BIANNUAL",
  ANNUAL: "ANNUAL",
};

export const PAYMENT_RECEIVER = {
  SAME_ACCOUNT: "SAME_ACCOUNT",
  ANOTHER_ACCOUNT: "ANOTHER_ACCOUNT",
};

export const CONTACT_EXERCISE_FRAME = {
  EMPLOYEE: "EMPLOYEE",
  INDEPENDENT: "INDEPENDENT",
};

export const SOCIO_PROFESSIONAL_CATEGORIES = {
  FARMERS: "FARMERS",
  ARTISANS: "ARTISANS",
  EXECUTIVES: "EXECUTIVES",
  EXECUTIVES_AND_PUBLIC_SERVICE_EMPLOYEES:
    "EXECUTIVES AND PUBLIC SERVICE EMPLOYEES",
  ENTREPRENEURS: "ENTREPRENEURS",
  TRADERS_AND_SIMILAR: "TRADERS AND SIMILAR",
  EMPLOYEES_AND_SUPERVISORS: "EMPLOYEES AND SUPERVISORS",
  WORKERS: "WORKERS",
  PEOPLE_WITHOUT_PROFESSIONAL_ACTIVITY: "PEOPLE WITHOUT PROFESSIONAL ACTIVITY",
  LIBERAL_PROFESSIONS_AND_SIMILAR: "LIBERAL PROFESSIONS AND SIMILAR",
  RETIREES: "RETIREES",
};

export const REPETITION_INDEX = [
  "A",
  "B (Bis)",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T (Ter)",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const INSURANCE_PLAN_ATTACHMENTS_TYPES = {
  STATUSES: "STATUSES",
  IPID: "IPID",
  INFO_NOTICE: "INFO_NOTICE",
  BROCHURE: "BROCHURE",
};

export const SUBSCRIPTION_STATUSES = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  SIGNED: "SIGNED",
};

export const DEBIT_POSSIBLE_DATES = ["5", "10", "15", "20", "25", "30"];

export const JOB_LIST = {
  PROFESSIONAL_ACTIVITY_OTHER_SECTOR: "PROFESSIONAL_ACTIVITY_OTHER_SECTOR",
  PROFESSIONAL_ACTIVITY_PRIVATE_SECTOR: "PROFESSIONAL_ACTIVITY_PRIVATE_SECTOR",
  FARMER: "FARMER",
  ARTISAN: "ARTISAN",
  ENTREPRENEUR: "ENTREPRENEUR",
  TRADER: "TRADER",
  SEARCHING_FOR_A_JOB: "SEARCHING_FOR_A_JOB",
  INDEPENDENT_ENTREPRENEUR: "INDEPENDENT_ENTREPRENEUR",
  STUDENT: "STUDENT",
  POLITICALLY_EXPOSED_ADMINISTRATIVE_FUNCTION:
    "POLITICALLY_EXPOSED_ADMINISTRATIVE_FUNCTION",
  POLITICALLY_EXPOSED_JURISDICTIONAL_FUNCTION:
    "POLITICALLY_EXPOSED_JURISDICTIONAL_FUNCTION",
  POLITICALLY_EXPOSED_MILITARY_FUNCTION:
    "POLITICALLY_EXPOSED_MILITARY_FUNCTION",
  POLITICALLY_EXPOSED_POLITICAL_OR_DIPLOMATIC_FUNCTION:
    "POLITICALLY_EXPOSED_POLITICAL_OR_DIPLOMATIC_FUNCTION",
  STATE_CIVIL_SERVICE: "STATE_CIVIL_SERVICE",
  HOSPITAL_CIVIL_SERVICE: "HOSPITAL_CIVIL_SERVICE",
  TERRITORIAL_CIVIL_SERVICE: "TERRITORIAL_CIVIL_SERVICE",
  AGRICULTURAL_PROFESSION: "AGRICULTURAL_PROFESSION",
  LIBERAL_PROFESSION: "LIBERAL_PROFESSION",
  RETIRED: "RETIRED",
  UNEMPLOYED: "UNEMPLOYED",
};

export const REGEX = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
};

export const DEPARTMENTS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "2A",
  "2B",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
];

export const CONTENT_TYPES = {
  XLSX: "application/xlsx",
  PDF: "application/pdf",
  JPEG: "image/jpeg",
  PNG: "image/png",
};

export const MONTH_OPTIONS = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const ATTACHMENT_TYPES = {
  PRESCRIPTION: "PRESCRIPTION",
  BILL: "BILL",
  RESULT: "RESULT",
  VIGNETTE: "VIGNETTE",
  TREATMENT_SHEET: "TREATMENT_SHEET",
  REPORT: "REPORT",
  SUPPORT_FORM: "SUPPORT_FORM",
  HONORARY_NOTES: "HONORARY_NOTES",
  OTHER: "OTHER",
  ORGANIZATION_BACKGROUND: "organization.background",
  ORGANIZATION_FAVICON: "organization.favicon",
  PROFILE: "PROFILE",
  SIGNATURE: "SIGNATURE",
  INVOICE: "INVOICE",
  DUTY_OF_ADVICE: "DUTY_OF_ADVICE",
  PAYER_CIN: "PAYER_CIN",
  CIN: "CIN",
  PAYMENT_ACCOUNT: "PAYMENT_ACCOUNT",
  REFUND_ACCOUNT: "REFUND_ACCOUNT",
  SOCIAL_SECURITY_CERTIFICATE: "SOCIAL_SECURITY_CERTIFICATE",
  MEMBERSHIP_FORM: "MEMBERSHIP_FORM",
  IPID: "IPID",
  COVERAGE_GRID: "COVERAGE_GRID",
  KBIS: "KBIS",
  INFO_NOTICE: "INFO_NOTICE",
  BROCHURE: "BROCHURE",
  COMMISSION_STATEMENT: "COMMISSION_STATEMENT",
  ICON: "ICON",
};

export const BANK_ACCOUNT_OWNER_TYPE = {
  PARTICULAR: "PARTICULAR",
  COMPANY: "COMPANY",
};

export const REGEX_EXPS = {
  zipCode: /^(0[1-9]|[1-8][0-9]|9[0-8])[0-9]{3}$/,
  mobilePhone: /^(06|07)[0-9]{8}$/,
  phone: /^(0)[0-9]{9}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/,
};

export const BENEFICIARY_TYPES = {
  ADHERENT: "ADHERENT",
  SPOUSE: "SPOUSE",
  CHILD: "CHILD",
};
