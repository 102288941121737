import { Col, Row } from "antd";
import dayjs from "dayjs";
import { Formik } from "formik";
import { get } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  MdAccountCircle,
  MdArrowBackIos,
  MdArrowForwardIos,
  MdCrisisAlert,
  MdDonutLarge,
  MdFreeCancellation,
  MdNumbers,
  MdPriceChange,
} from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card/Main";
import Form from "shared/components/Form";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import graphql from "utils/api/graphql";
import { DATE_FORMAT, ORGANIZATION_TYPES, RISKS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ project, onSubmit }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const { user } = useContext(StoreContext);
  const FAST = t("DETAIL.TEMPLATES.FAST", { returnObjects: true });
  return (
    <>
      <Header
        key={"header"}
        title={FAST.DETAIL.TEMPLATES.CONTACT.TITLE}
        actions={[]}
      />
      <Formik
        initialValues={{
          contact: get(project, "contact.name", ""),
          broker: get(user, "organization.id", ""),
          status: { id: get(project, "status.id", "") },
          fields: {
            ...project.fields,
            quote: {
              number: get(project, "fields.quote.number"),
              effectiveDate: dayjs(
                get(project, "fields.quote.effectiveDate"),
                DATE_FORMAT
              ),

              creationDate: dayjs(
                get(project, "fields.quote.creationDate"),
                DATE_FORMAT
              ),
            },
          },
        }}
        onSubmit={onSubmit}
        validationSchema={validation(
          t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, { returnObjects: true })
        )}
      >
        {({ isValid, handleSubmit, setFieldValue, ...formProps }) => (
          <div className="fast-container">
            <Card key="card" title={FAST.TITLE} subtitle={FAST.SUBTITLE}>
              <Form
                type="vertical"
                title={FAST.FORM.INFORMATION}
                subtitle={FAST.FORM.REQUIRED}
              >
                <Select
                  type="async"
                  node={{
                    query: graphql.queries.ORGANIZATIONS,
                    options: {
                      variables: {
                        where: {
                          id: get(user, "organization.id", ""),
                        },
                      },
                    },
                    accessors: { root: "organizations.data" },
                  }}
                  placeholder={FAST.FORM.BROKER.PLACEHOLDER}
                  label={FAST.FORM.BROKER.LABEL}
                  icon={<MdAccountCircle size={14} />}
                  {...bindInputProps({
                    name: "broker",
                    ...formProps,
                  })}
                  disabled
                />
                <Input
                  placeholder={FAST.FORM.CLIENT.PLACEHOLDER}
                  label={FAST.FORM.CLIENT.LABEL}
                  icon={<MdAccountCircle size={14} />}
                  {...bindInputProps({ name: "contact", ...formProps })}
                />
              </Form>
              <Form
                type="vertical"
                title={FAST.FORM.PROJECT}
                subtitle={FAST.FORM.REQUIRED}
              >
                <Select
                  placeholder={FAST.FORM.RISK.PLACEHOLDER}
                  label={FAST.FORM.RISK.LABEL}
                  icon={<MdCrisisAlert size={14} />}
                  options={RISKS.map((risk) => ({
                    label: FAST.RISK_OPTIONS[risk],
                    value: risk,
                  }))}
                  {...bindInputProps({ name: "fields.risk", ...formProps })}
                />
                <Select
                  type="async"
                  node={{
                    query: graphql.queries.ORGANIZATIONS,
                    options: {
                      variables: {
                        where: {
                          type: ORGANIZATION_TYPES.INS,
                        },
                      },
                    },
                    accessors: {
                      root: "organizations.data",
                      value: "insurancePlans.0.id",
                    },
                  }}
                  placeholder={FAST.FORM.INSURER.PLACEHOLDER}
                  label={FAST.FORM.INSURER.LABEL}
                  icon={<MdAccountCircle size={14} />}
                  {...bindInputProps({
                    name: "fields.insurancePlans.0",
                    ...formProps,
                  })}
                />
                <Select
                  type="async"
                  node={{
                    query: graphql.queries.PROCESS_STATUSES,
                    options: {
                      variables: {
                        where: { process: { id: project.status.process.id } },
                      },
                    },
                    accessors: { root: "processStatuses.data" },
                  }}
                  placeholder={FAST.FORM.STATUS.PLACEHOLDER}
                  label={FAST.FORM.STATUS.LABEL}
                  icon={<MdDonutLarge size={14} />}
                  {...bindInputProps({ name: "status.id", ...formProps })}
                />
              </Form>
              <Form
                type="vertical"
                title={FAST.FORM.QUOTE}
                subtitle={FAST.FORM.REQUIRED}
              >
                <Input
                  placeholder={FAST.FORM.QUOTE_NUMBER.PLACEHOLDER}
                  label={FAST.FORM.QUOTE_NUMBER.LABEL}
                  icon={<MdNumbers size={14} />}
                  {...bindInputProps({
                    name: "fields.quote.number",
                    ...formProps,
                  })}
                />
                <Input
                  name="fields.quote.effectiveDate"
                  type="date"
                  placeholder={FAST.FORM.EFFECTIVE_DATE.PLACEHOLDER}
                  label={FAST.FORM.EFFECTIVE_DATE.LABEL}
                  icon={<MdFreeCancellation size={14} />}
                  onChange={(_, dateS) =>
                    setFieldValue("fields.quote.effectiveDate", dateS)
                  }
                  format={DATE_FORMAT}
                  defaultValue={formProps.values.fields.quote.effectiveDate}
                />
                <Input
                  name="fields.quote.creationDate"
                  type="date"
                  placeholder={FAST.FORM.CREATION_DATE.PLACEHOLDER}
                  label={FAST.FORM.CREATION_DATE.LABEL}
                  icon={<MdFreeCancellation size={14} />}
                  onChange={(_, dateS) =>
                    setFieldValue("fields.quote.creationDate", dateS)
                  }
                  format={DATE_FORMAT}
                  defaultValue={formProps.values.fields.quote.creationDate}
                />
              </Form>
              <Form
                type="vertical"
                title={FAST.FORM.BUDGET}
                subtitle={FAST.FORM.REQUIRED}
              >
                <Input
                  type="number"
                  placeholder={FAST.FORM.PROPOSED_PREMIUM.PLACEHOLDER}
                  label={FAST.FORM.PROPOSED_PREMIUM.LABEL}
                  icon={<MdPriceChange size={14} />}
                  suffix="€"
                  {...bindInputProps({
                    name: "fields.budget.proposed",
                    ...formProps,
                  })}
                />

                <Input
                  type="number"
                  placeholder={FAST.FORM.ACTUAL_PREMIUM.PLACEHOLDER}
                  label={FAST.FORM.ACTUAL_PREMIUM.LABEL}
                  icon={<MdPriceChange size={14} />}
                  suffix="€"
                  {...bindInputProps({
                    name: "fields.budget.actual",
                    ...formProps,
                  })}
                />
              </Form>
              <Form
                type="vertical"
                title={FAST.FORM.DOCUMENTS}
                subtitle={FAST.FORM.REQUIRED}
              ></Form>
            </Card>
            <Row>
              <Col lg={12} xs={24}>
                <Button onClick={() => {}} type="secondary--link">
                  <MdArrowBackIos size={16} />
                  {FAST.ACTIONS.PREVIOUS}
                </Button>
              </Col>
              <Col align="right" lg={12} xs={24}>
                <Button onClick={handleSubmit} type="primary">
                  {FAST.ACTIONS.SAVE}
                  <MdArrowForwardIos size={16} />
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </>
  );
};

export default Create;
