import { Col, Row } from "antd";
import cuid from "cuid";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCallReceived,
  MdOutlineQrCode,
} from "react-icons/md";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { PAYMENT_RECEIVER, SUBSCRIPTION_FORM_KEYS } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Refund = ({
  formProps,
  setDisabled,
  values,
  setPercent,
  setFieldValue,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });
  const PAYMENT_RECEIVER_VALUES = {
    [PAYMENT_RECEIVER.SAME_ACCOUNT]: () =>
      setFieldValue("payment.receiver", get(values, "payment.payer")),
    [PAYMENT_RECEIVER.ANOTHER_ACCOUNT]: () =>
      setFieldValue("payment.receiver", {
        id: cuid(),
      }),
  };

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.REFUND}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.REFUND.TITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              icon={<MdOutlineCallReceived size={16} />}
              label={
                SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.REFUND.ACCOUNT.LABEL
              }
              values={[
                {
                  value: PAYMENT_RECEIVER.SAME_ACCOUNT,
                  label:
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.REFUND.ACCOUNT
                      .OPTIONS.USER_ACCOUNT,
                  popover:
                    !(
                      get(values, "payment.payer.IBAN") &&
                      get(values, "payment.payer.BIC")
                    ) &&
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.REFUND.ACCOUNT
                      .POPOVER,
                },
                {
                  value: PAYMENT_RECEIVER.ANOTHER_ACCOUNT,
                  label:
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.REFUND.ACCOUNT
                      .OPTIONS.OTHER,
                },
              ]}
              {...bindInputProps({
                name: "isPayerReceiver",
                values,
                ...formProps,
              })}
              onBlur={() => setPercent(values)}
              disabled={
                !(
                  get(values, "payment.payer.IBAN") &&
                  get(values, "payment.payer.BIC")
                )
              }
              onChange={(value) => {
                setFieldValue("isPayerReceiver", value);
                setDisabled((prev) => ({
                  ...prev,
                  proof: {
                    ...prev.proof,
                    refundAccount: value === PAYMENT_RECEIVER.SAME_ACCOUNT,
                  },
                }));
                PAYMENT_RECEIVER_VALUES[value]();
              }}
              required={isFieldRequired("isPayerReceiver")}
            />
          </Col>
          {get(values, "isPayerReceiver") ===
            PAYMENT_RECEIVER.ANOTHER_ACCOUNT && (
            <>
              <Col lg={12} xs={12}>
                <Input
                  label={SUBSCRIPTION.FORM.OWNER.LASTNAME.LABEL}
                  icon={<MdOutlineBadge size={16} />}
                  placeholder={SUBSCRIPTION.FORM.OWNER.LASTNAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.receiver.owner.lastName",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  required={isFieldRequired("payment.receiver.owner.lastName")}
                />
              </Col>
              <Col lg={12} xs={12}>
                <Input
                  label={SUBSCRIPTION.FORM.OWNER.FIRSTNAME.LABEL}
                  icon={<MdOutlineBadge size={16} />}
                  placeholder={SUBSCRIPTION.FORM.OWNER.FIRSTNAME.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.receiver.owner.firstName",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  required={isFieldRequired("payment.receiver.owner.firstName")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  label={SUBSCRIPTION.FORM.IBAN.LABEL}
                  icon={<MdOutlineQrCode size={16} />}
                  placeholder={SUBSCRIPTION.FORM.IBAN.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.receiver.IBAN",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  required={isFieldRequired("payment.receiver.IBAN")}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Input
                  label={SUBSCRIPTION.FORM.BIC.LABEL}
                  icon={<MdOutlineQrCode size={16} />}
                  placeholder={SUBSCRIPTION.FORM.BIC.PLACEHOLDER}
                  {...bindInputProps({
                    name: "payment.receiver.BIC",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  required={isFieldRequired("payment.receiver.BIC")}
                />
              </Col>
            </>
          )}
        </Row>
      </Form>
    </div>
  );
};

export default Refund;
