import { Table } from "antd";
import { get } from "lodash";
import omitDeep from "omit-deep-lodash";
import { useTranslation } from "react-i18next";
import { FiDownload } from "react-icons/fi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Button from "shared/components/Button";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";
import { downloadDocument } from "utils/helpers/files";

function List({ data, count, setValues, skip, downloadSingleDocument }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT);
  const LIST = t("LIST", { returnObjects: true });
  const MONTHS = t("MONTHS", { returnObjects: true });

  const columns = [
    {
      title: LIST.YEAR,
      dataIndex: "year",
      render: (record) => <strong>{record}</strong> || "-",
    },
    {
      title: LIST.MONTH,
      dataIndex: "month",
      render: (record) => MONTHS[record] || "-",
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id, { attachment }) => (
        <div className="d-flex justify--end">
          <Button
            onClick={async () =>
              await downloadSingleDocument({
                variables: { where: { id: get(attachment, "id") } },
                onCompleted: ({ downloadSingleDocument }) => {
                  const { document } = omitDeep(
                    downloadSingleDocument,
                    "__typename"
                  );
                  downloadDocument(document);
                },
              })
            }
            type="primary--link"
            icon={<FiDownload size={20} />}
          >
            {LIST.DOWNLOAD}
          </Button>
          <div
            onClick={() =>
              navigate(
                `${SCREENS.PRIVATE.ADMIN.COMMISSION_STATEMENTS.DELETE.path}/${id}`
              )
            }
          >
            <MdOutlineDeleteOutline size={24} color={COLORS.C_DANGER} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
