import { Col, Row, Table, Tag } from "antd";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { MdOpenInNew, MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Pagination from "shared/components/Pagination";
import { NAME_SPACES } from "shared/locales/constants";
import { COLORS } from "shared/style/colors";
import { SCREENS } from "utils/constants";

function List({ data, count, setValues, skip }) {
  const navigate = useNavigate();
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.COVERAGE);
  const LIST = t("LIST", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });

  const columns = [
    {
      title: LIST.NAME,
      dataIndex: "identifier",
      render: (item) => item || "-",
    },
    {
      title: LIST.TYPE,
      dataIndex: "type",
      render: (item) => FORM.CATEGORIES[item] || "-",
    },
    {
      title: LIST.INSURANCE_PLAN,
      dataIndex: "insurancePlan",
      render: (item) => <Tag>{get(item, "name")}</Tag>,
    },
    {
      title: LIST.ACTIONS,
      dataIndex: "id",
      align: "right",
      render: (id) => (
        <Row gutter={8} justify="end" align="center" wrap={false}>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.COVERAGES.DETAIL.path}/${id}`)
            }
          >
            <MdOpenInNew size={24} color={COLORS.C_PRIMARY} />
          </Col>
          <Col
            onClick={() =>
              navigate(`${SCREENS.PRIVATE.ADMIN.COVERAGES.DELETE.path}/${id}`)
            }
          >
            <MdOutlineDeleteOutline size={24} color={COLORS.C_DANGER} />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <Table
        dataSource={data}
        className="table__wrapper"
        columns={columns}
        rowKey={"id"}
        pagination={false}
        scroll={{ x: true }}
      />
      <Pagination count={count} setValues={setValues} skip={skip} />
    </>
  );
}

export default List;
