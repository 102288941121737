import { Col, Row, Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineChangeCircle,
  MdOutlineFamilyRestroom,
  MdOutlineHealthAndSafety,
  MdOutlineQrCodeScanner,
  MdOutlineSubtitles,
  MdPeopleOutline,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  FAMILY_SITUATION,
  GENDER,
  SOCIAL_REGIMES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindInputProps } from "utils/helpers/input";

const Adherent = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
  setDisabled,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    <div
      className="subscription-form--wrapper"
      id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.ADHERENT}-section`}
    >
      <Form
        type="vertical"
        title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.TITLE}
        subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.SUBTITLE}
      >
        <Row gutter={[16, 16]}>
          <Col lg={24} xs={24}>
            <Input
              type="radio"
              variant="group"
              values={[
                {
                  value: GENDER.MALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MR,
                },
                {
                  value: GENDER.FEMALE,
                  label: SUBSCRIPTION.FORM.GENDER.OPTIONS.MRS,
                },
              ]}
              label={
                <div className="subscription-form--group-label">
                  <MdPeopleOutline icon="man" size={16} />
                  <span className="group-label--text">
                    {SUBSCRIPTION.FORM.GENDER.LABEL}
                  </span>
                </div>
              }
              {...bindInputProps({
                name: "project.contact.user.gender",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              disabled
              required={isFieldRequired("project.contact.user.gender")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
              label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              {...bindInputProps({
                name: "project.contact.user.lastname",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              disabled
              required={isFieldRequired("project.contact.user.lastname")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
              icon={<MdOutlineBadge size={16} />}
              placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
              {...bindInputProps({
                name: "project.contact.user.firstname",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              disabled
              required={isFieldRequired("project.contact.user.firstname")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
              type="date"
              variant="simple"
              label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
              defaultValue={dayjs(
                get(values, "project.contact.user.birthDate")
              )}
              icon={<MdOutlineCake size={16} />}
              onChange={(_, date) => {
                setFieldValue("project.contact.user.birthDate", date);
                setPercent(values);
              }}
              format={DATE_FORMAT}
              disabled
              required={isFieldRequired("project.contact.user.birthDate")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Select
              icon={<MdOutlineHealthAndSafety size={16} />}
              options={Object.entries(SOCIAL_REGIMES).map(([key, value]) => ({
                value,
                label: SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
              }))}
              label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
              placeholder={SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER}
              {...bindInputProps({
                name: "project.contact.socialRegime",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              disabled
              required={isFieldRequired("project.contact.socialRegime")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <Input
              label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
              icon={<MdOutlineSubtitles size={16} />}
              placeholder={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER}
              {...bindInputProps({
                name: "project.contact.socialSecurityNumber",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              required={isFieldRequired("project.contact.socialSecurityNumber")}
            />
          </Col>
          <Col lg={24} xs={24}>
            <div className="d-flex justify--between">
              <MdOutlineChangeCircle size={16} />
              <span className="contact-stepper__content--radio--title">
                {SUBSCRIPTION.FORM.TELETRANSMISSION.LABEL}
              </span>
              <Switch
                className="contact-stepper__content--radio--switch button__switch"
                checkedChildren={SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.YES}
                unCheckedChildren={
                  SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.NO
                }
                defaultChecked={get(
                  values,
                  "project.contact.fields.teletransmission"
                )}
                checked={get(values, "project.contact.fields.teletransmission")}
                onChange={(state) => {
                  if (!state) {
                    setFieldValue(
                      "spouse.identity.fields.teletransmission",
                      state
                    );
                    get(values, "children").map((_, index) =>
                      setFieldValue(
                        `children.${index}.identity.fields.teletransmission`,
                        state
                      )
                    );
                  }
                  setFieldValue(
                    "project.contact.fields.teletransmission",
                    state
                  );
                  setFieldValue(
                    "project.contact.fields.affiliateOrganizationCode",
                    null
                  );
                  setDisabled((prev) => ({
                    ...prev,
                    [SUBSCRIPTION_FORM_KEYS.PROOF.SOCIAL_SECURITY_CERTIFICATE]:
                      !state,
                  }));
                }}
              />
            </div>
          </Col>
          {get(values, "project.contact.fields.teletransmission") && (
            <>
              <Col lg={24} xs={24}>
                <Input
                  label={SUBSCRIPTION.FORM.AFFILIATE_ORGANIZATION_CODE.LABEL}
                  icon={<MdOutlineQrCodeScanner size={16} />}
                  placeholder={
                    SUBSCRIPTION.FORM.AFFILIATE_ORGANIZATION_CODE.PLACEHOLDER
                  }
                  {...bindInputProps({
                    name: "project.contact.fields.affiliateOrganizationCode",
                    values,
                    ...formProps,
                    onBlur: () => setPercent(values),
                  })}
                  required={isFieldRequired(
                    "project.contact.fields.affiliateOrganizationCode"
                  )}
                />
              </Col>
              <Col lg={24} xs={24}>
                <Alert
                  showIcon
                  type="info"
                  message={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.ADHERENT.INFO
                  }
                />
              </Col>
            </>
          )}
          <Col lg={24} xs={24}>
            <Select
              label={SUBSCRIPTION.FORM.FAMILY_SITUATION.LABEL}
              icon={<MdOutlineFamilyRestroom size={16} />}
              placeholder={SUBSCRIPTION.FORM.FAMILY_SITUATION.PLACEHOLDER}
              type="simple"
              options={Object.entries(FAMILY_SITUATION).map(([key, value]) => ({
                value,
                label: SUBSCRIPTION.FORM.FAMILY_SITUATION.OPTIONS[key],
              }))}
              {...bindInputProps({
                name: "project.contact.fields.familySituation",
                values,
                ...formProps,
                onBlur: () => setPercent(values),
              })}
              required={isFieldRequired(
                "project.contact.fields.familySituation"
              )}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Adherent;
