import { useTranslation } from "react-i18next";
import Modal from "shared/components/Modal";
import { NAME_SPACES } from "shared/locales/constants";

const Delete = ({ onSubmit, cancel, visible }) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION);
  const DELETE = t("DELETE", { returnObjects: true });
  return (
    <Modal
      okText={DELETE.OK}
      cancelText={DELETE.CANCEL}
      onCancel={cancel}
      onOk={onSubmit}
      message={DELETE.MESSAGE}
      visible={visible}
    />
  );
};

export default Delete;
