import * as yup from "yup";

const validator = (messages) => {
  const requiredString = yup
    .string()
    .typeError(messages.REQUIRED)
    .required(messages.REQUIRED);
  return yup.object().shape({
    organization: yup.object().shape({
      name: requiredString,
    }),
    user: yup.object().shape({
      gender: requiredString,
      firstname: requiredString,
      lastname: requiredString,
      phone: requiredString,
      email: requiredString,
    }),
  });
};

export default validator;
