import { MdOutlineDraw } from "react-icons/md";
import Button from "shared/components/Button";

const Draft = ({ disabled, translation, signDocument }) => {
  return (
    <Button
      type="secondary"
      alignment="center"
      disabled={disabled}
      onClick={() => signDocument()}
    >
      <MdOutlineDraw size={16} />
      {translation.E_SIGNATURE}
    </Button>
  );
};

export default Draft;
