import { NAME_SPACES } from "shared/locales/constants";
import broker from "./broker";
import categoriesWeight from "./categories-weight";
import commission from "./commission";
import commissionStatement from "./commission-statement";
import coverage from "./coverage";
import coverageBenefit from "./coverage-benefit";
import flow from "./flow";
import insurancePlan from "./insurance-plan";
import organization from "./organization";
import pricer from "./pricer";
import process from "./process";
import qualification from "./qualification";
import qualifier from "./qualifier";
import source from "./source";
import tracer from "./tracer";
import user from "./user";
import webhook from "./webhook";
import workflow from "./workflow";

const translation = {
  [NAME_SPACES.PRIVATE.ADMIN.SOURCE]: source,
  [NAME_SPACES.PRIVATE.ADMIN.TRACER]: tracer,
  [NAME_SPACES.PRIVATE.ADMIN.FLOW]: flow,
  [NAME_SPACES.PRIVATE.ADMIN.PROCESS]: process,
  [NAME_SPACES.PRIVATE.ADMIN.USER]: user,
  [NAME_SPACES.PRIVATE.ADMIN.INSURANCE_PLAN]: insurancePlan,
  [NAME_SPACES.PRIVATE.ADMIN.COVERAGE]: coverage,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION]: qualification,
  [NAME_SPACES.PRIVATE.ADMIN.COVERAGE_BENEFIT]: coverageBenefit,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFICATION]: qualification,
  [NAME_SPACES.PRIVATE.ADMIN.QUALIFIER]: qualifier,
  [NAME_SPACES.PRIVATE.ADMIN.WEBHOOK]: webhook,
  [NAME_SPACES.PRIVATE.ADMIN.BROKER]: broker,
  [NAME_SPACES.PRIVATE.ADMIN.WORKFLOW]: workflow,
  [NAME_SPACES.PRIVATE.ADMIN.PRICER]: pricer,
  [NAME_SPACES.PRIVATE.ADMIN.COMMISSION_STATEMENT]: commissionStatement,
  [NAME_SPACES.PRIVATE.ADMIN.COMMISSION]: commission,
  [NAME_SPACES.PRIVATE.ADMIN.CATEGORIES_WEIGHT]: categoriesWeight,
  [NAME_SPACES.PRIVATE.ADMIN.ORGANIZATION]: organization,
};

export default translation;
