import { useFormik } from "formik";
import { get } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiUserCircle } from "react-icons/bi";
import {
  MdAlternateEmail,
  MdArrowBackIos,
  MdArrowForwardIos,
  MdLocationCity,
  MdLocationPin,
  MdOutlineBadge,
  MdOutlineNumbers,
  MdPhone,
} from "react-icons/md";
import Button from "shared/components/Button";
import Card from "shared/components/Card";
import Radio from "shared/components/Form/Radio";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { CONTACT_POINT_SYSTEM, GENDER, USER_TYPES } from "utils/constants";
import { bindInputProps } from "utils/helpers/input";
import validation from "./validation";

const Create = ({ onSubmit, cancel }) => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.COLLABORATOR);
  const CREATE = t("CREATE", { returnObjects: true });
  const FORM = t("FORM", { returnObjects: true });
  const { values, handleSubmit, resetForm, setFieldValue, ...formProps } =
    useFormik({
      initialValues: {
        meta: { type: null },
        gender: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        orias: null,
        socialReason: null,
        rcsNumber: null,
        code: null,
        telecoms: {
          [CONTACT_POINT_SYSTEM.ADDRESS]: { value: null },
          [CONTACT_POINT_SYSTEM.ZIP_CODE]: { value: null },
          [CONTACT_POINT_SYSTEM.CITY]: { value: null },
          [CONTACT_POINT_SYSTEM.PHONE]: { value: null },
          [CONTACT_POINT_SYSTEM.EMAIL]: { value: null },
        },
      },
      onSubmit: onSubmit,
      validationSchema: validation(
        t(`${NAME_SPACES.ERRORS}:VALIDATIONS`, {
          returnObjects: true,
        })
      ),
    });

  return (
    <>
      <Header title={CREATE.TITLE} />
      <div className="fast-container">
        <Card head title={FORM.CREATE.TITLE} subtitle={FORM.CREATE.SUBTITLE}>
          <div className="wrapper--forms">
            <Radio
              isHorizontal={false}
              label={FORM.STATUS}
              options={[
                {
                  label: FORM.SALARY,
                  value: USER_TYPES.BROKER_EMPLOYEE.type,
                },
                {
                  label: FORM.AGENT,
                  value: get(user, "organization.broker.isInternal")
                    ? USER_TYPES.INSURANCE_REPRESENTATIVE.type
                    : USER_TYPES.BROKER_REPRESENTATIVE.type,
                },
              ]}
              icon={<BiUserCircle size={14} />}
              {...bindInputProps({ name: "meta.type", values, ...formProps })}
              onClick={(value) => {
                if (value === USER_TYPES.BROKER_EMPLOYEE.type) {
                  setFieldValue("orias", null);
                }
                setFieldValue("meta.type", value);
              }}
            />
            {[
              USER_TYPES.BROKER_REPRESENTATIVE.type,
              USER_TYPES.INSURANCE_REPRESENTATIVE.type,
            ].includes(get(values, "meta.type")) && (
              <div className="grid--2">
                <Input
                  placeholder={FORM.NUMBER}
                  label={FORM.NUMBER}
                  icon={<MdOutlineNumbers size={14} />}
                  {...bindInputProps({
                    name: "orias",
                    values,
                    ...formProps,
                  })}
                />
                <Input
                  placeholder={FORM.SOCIAL_REASON}
                  label={FORM.SOCIAL_REASON}
                  icon={<MdOutlineNumbers size={14} />}
                  {...bindInputProps({
                    name: "socialReason",
                    values,
                    ...formProps,
                  })}
                />
              </div>
            )}
            {[USER_TYPES.INSURANCE_REPRESENTATIVE.type].includes(
              get(values, "meta.type")
            ) && (
              <>
                <div className="grid--2">
                  <Input
                    icon={<MdOutlineNumbers size={14} />}
                    placeholder={FORM.AGENT_CODE}
                    label={FORM.AGENT_CODE}
                    {...bindInputProps({ name: "code", ...formProps })}
                  />
                  <Input
                    icon={<MdOutlineNumbers size={14} />}
                    placeholder={FORM.RCS_NUMBER}
                    label={FORM.RCS_NUMBER}
                    {...bindInputProps({ name: "rcsNumber", ...formProps })}
                  />
                  <Input
                    placeholder={FORM.ORGANIZATION_PHONE}
                    label={FORM.ORGANIZATION_PHONE}
                    icon={<MdPhone size={14} />}
                    {...bindInputProps({
                      name: `telecoms.${CONTACT_POINT_SYSTEM.PHONE}.value`,
                      ...formProps,
                    })}
                  />
                  <Input
                    placeholder={FORM.ORGANIZATION_EMAIL}
                    label={FORM.ORGANIZATION_EMAIL}
                    icon={<MdAlternateEmail size={14} />}
                    {...bindInputProps({
                      name: `telecoms.${CONTACT_POINT_SYSTEM.EMAIL}.value`,
                      ...formProps,
                    })}
                  />
                  <Input
                    placeholder={FORM.POSTAL_ADDRESS}
                    label={FORM.POSTAL_ADDRESS}
                    icon={<MdLocationPin size={14} />}
                    {...bindInputProps({
                      name: `telecoms.${CONTACT_POINT_SYSTEM.ADDRESS}.value`,
                      ...formProps,
                    })}
                  />
                  <Input
                    placeholder={FORM.ZIP_CODE}
                    label={FORM.ZIP_CODE}
                    icon={<MdLocationPin size={14} />}
                    {...bindInputProps({
                      name: `telecoms.${CONTACT_POINT_SYSTEM.ZIP_CODE}.value`,
                      ...formProps,
                    })}
                  />
                  <Input
                    icon={<MdLocationCity size={14} />}
                    placeholder={FORM.CITY}
                    label={FORM.CITY}
                    {...bindInputProps({
                      name: `telecoms.${CONTACT_POINT_SYSTEM.CITY}.value`,
                      ...formProps,
                    })}
                  />
                </div>
              </>
            )}
            <Radio
              isHorizontal={false}
              label={FORM.CIVILITY}
              options={[
                {
                  label: FORM.MALE,
                  value: GENDER.MALE,
                },
                {
                  label: FORM.FEMALE,
                  value: GENDER.FEMALE,
                },
              ]}
              icon={<BiUserCircle size={14} />}
              {...bindInputProps({ name: "gender", values, ...formProps })}
            />
            <Input
              placeholder={FORM.FIRSTNAME}
              label={FORM.FIRSTNAME}
              icon={<MdOutlineBadge size={14} />}
              {...bindInputProps({ name: "firstname", values, ...formProps })}
            />
            <Input
              placeholder={FORM.LASTNAME}
              label={FORM.LASTNAME}
              icon={<MdOutlineBadge size={14} />}
              {...bindInputProps({ name: "lastname", values, ...formProps })}
            />
            <Input
              placeholder={FORM.EMAIL}
              label={FORM.EMAIL}
              icon={<MdAlternateEmail size={14} />}
              {...bindInputProps({ name: "email", values, ...formProps })}
            />
            <Input
              placeholder={FORM.PHONE}
              label={FORM.PHONE}
              icon={<MdPhone size={14} />}
              {...bindInputProps({ name: "phone", values, ...formProps })}
            />
          </div>
        </Card>
        <div className="d-flex justify--between">
          <Button onClick={cancel} type="secondary--link">
            <MdArrowBackIos size={16} />
            {FORM.PREVIOUS}
          </Button>

          <Button onClick={handleSubmit} type="primary">
            {FORM.SAVE}
            <MdArrowForwardIos size={16} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default Create;
