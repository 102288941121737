import { Col, Row, Switch } from "antd";
import dayjs from "dayjs";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import {
  MdOutlineBadge,
  MdOutlineCake,
  MdOutlineChangeCircle,
  MdOutlineHealthAndSafety,
  MdOutlineSubtitles,
} from "react-icons/md";
import Alert from "shared/components/Alert";
import Form from "shared/components/Form";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import { NAME_SPACES } from "shared/locales/constants";
import {
  DATE_FORMAT,
  RELATIONSHIP,
  SOCIAL_REGIMES,
  SUBSCRIPTION_FORM_KEYS,
} from "utils/constants";
import { bindArrayInputProps } from "utils/helpers/input";

const Children = ({
  formProps,
  values,
  setFieldValue,
  setPercent,
  isFieldRequired,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.CONTRACT);
  const SUBSCRIPTION = t("SUBSCRIPTION", { returnObjects: true });

  return (
    get(values, "project.contact.relatedPersons", []).find(
      (person) => person.relationship === RELATIONSHIP.CHILD
    ) && (
      <div
        className="subscription-form--wrapper"
        id={`${SUBSCRIPTION_FORM_KEYS.ADDITIONAL_INFORMATION.CHILDREN}-section`}
      >
        <Form
          type="vertical"
          title={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.TITLE}
          subtitle={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.SUBTITLE}
        >
          <Row gutter={[16, 16]}>
            <Col lg={24} xs={24}>
              <Alert
                showIcon
                type="info"
                message={SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.INFO}
              />
            </Col>
            {values.children?.map((_, index) => [
              <Col lg={24} xs={24} key={"child-form-" + index}>
                <Form
                  type="highlight"
                  title={
                    SUBSCRIPTION.FORM.ADDITIONAL_INFORMATION.CHILDREN.CHILD
                      .TITLE +
                    " " +
                    (index + 1)
                  }
                >
                  <Row gutter={[16, 16]}>
                    <Col lg={24} xs={24}>
                      <div className="d-flex justify--between">
                        <MdOutlineChangeCircle size={16} />
                        <span className="contact-stepper__content--radio--title">
                          {SUBSCRIPTION.FORM.TELETRANSMISSION.LABEL}
                        </span>
                        <Switch
                          className="contact-stepper__content--radio--switch button__switch"
                          checkedChildren={
                            SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.YES
                          }
                          unCheckedChildren={
                            SUBSCRIPTION.FORM.TELETRANSMISSION.OPTIONS.NO
                          }
                          defaultChecked={get(
                            values,
                            `children.${index}.identity.fields.teletransmission`,
                            true
                          )}
                          checked={get(
                            values,
                            `children.${index}.identity.fields.teletransmission`,
                            true
                          )}
                          onChange={(e) => {
                            setFieldValue(
                              `children.${index}.identity.fields.teletransmission`,
                              e
                            );
                          }}
                          disabled={
                            !get(
                              values,
                              "project.contact.fields.teletransmission"
                            )
                          }
                        />
                      </div>
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                        label={SUBSCRIPTION.FORM.LAST_NAME.LABEL}
                        icon={<MdOutlineBadge size={16} />}
                        {...bindArrayInputProps({
                          parent: "children",
                          name: `identity.user.firstname`,
                          index,
                          values,
                          ...formProps,
                          onBlur: () => setPercent(values),
                        })}
                        required={isFieldRequired(
                          `children.${index}.identity.user.firstname`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        label={SUBSCRIPTION.FORM.FIRST_NAME.LABEL}
                        icon={<MdOutlineBadge size={16} />}
                        placeholder={SUBSCRIPTION.FORM.LAST_NAME.PLACEHOLDER}
                        {...bindArrayInputProps({
                          parent: "children",
                          name: `identity.user.lastname`,
                          index,
                          values,
                          ...formProps,
                          onBlur: () => setPercent(values),
                        })}
                        required={isFieldRequired(
                          `children.${index}.identity.user.lastname`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        label={SUBSCRIPTION.FORM.BIRTH_DATE.LABEL}
                        icon={<MdOutlineCake size={16} />}
                        placeholder={SUBSCRIPTION.FORM.BIRTH_DATE.PLACEHOLDER}
                        type="date"
                        variant="simple"
                        defaultValue={dayjs(
                          get(
                            values,
                            `children.${index}.identity.user.birthDate`
                          )
                        )}
                        format={DATE_FORMAT}
                        disabled
                        required={isFieldRequired(
                          `children.${index}.identity.user.birthDate`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Select
                        label={SUBSCRIPTION.FORM.MANDATORY_SCHEME.LABEL}
                        icon={<MdOutlineHealthAndSafety size={16} />}
                        placeholder={
                          SUBSCRIPTION.FORM.MANDATORY_SCHEME.PLACEHOLDER
                        }
                        type="simple"
                        options={Object.entries(SOCIAL_REGIMES).map(
                          ([key, value]) => ({
                            value,
                            label:
                              SUBSCRIPTION.FORM.MANDATORY_SCHEME.OPTIONS[key],
                          })
                        )}
                        {...bindArrayInputProps({
                          parent: "children",
                          name: `identity.socialRegime`,
                          index,
                          values,
                          ...formProps,
                        })}
                        disabled
                        required={isFieldRequired(
                          `children.${index}.identity.socialRegime`
                        )}
                      />
                    </Col>
                    <Col lg={24} xs={24}>
                      <Input
                        label={SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.LABEL}
                        icon={<MdOutlineSubtitles size={16} />}
                        placeholder={
                          SUBSCRIPTION.FORM.SOCIAL_SECURITY_NUMBER.PLACEHOLDER
                        }
                        {...bindArrayInputProps({
                          parent: "children",
                          name: `identity.socialSecurityNumber`,
                          index,
                          values,
                          ...formProps,
                          onBlur: () => setPercent(values),
                        })}
                        required={isFieldRequired(
                          `children.${index}.identity.socialSecurityNumber`
                        )}
                      />
                    </Col>
                  </Row>
                </Form>
              </Col>,
            ])}
          </Row>
        </Form>
      </div>
    )
  );
};

export default Children;
