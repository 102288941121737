import { get } from "lodash";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineReportProblem } from "react-icons/md";
import Alert from "shared/components/Alert";
import Card from "shared/components/Card";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import { CURRENCY_SYMBOL } from "utils/constants";
import ProductCard from "../ProductCard";

const All = ({
  data = [],
  selectedProducts = [],
  actions: {
    onAdd = () => {},
    onRemove = () => {},
    onChangeCommission = () => {},
  },
}) => {
  const { user } = useContext(StoreContext);
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const isSelected = (item) =>
    selectedProducts.includes(get(item, "insurancePlan.id"));
  return (
    <Card
      title={DETAIL.TEMPLATES.PRODUCTS.TITLE}
      subtitle={DETAIL.TEMPLATES.PRODUCTS.SUBTITLE}
    >
      <Alert
        type="info"
        showIcon
        message={DETAIL.TEMPLATES.PRODUCTS.INFO}
        icon={<MdOutlineReportProblem size={16} />}
      />
      <div className="grid--1 m-top-16">
        {data.map((cost, index) => {
          const [integer, decimal = "00"] = get(cost, "cost", "-")
            ?.toString()
            ?.split(".") || ["-"];
          const currency = CURRENCY_SYMBOL[get(cost, "currency") || "DEFAULT"];
          const commissions = get(
            cost,
            "insurancePlan.distributions.0.commissions",
            []
          );
          return (
            <ProductCard
              key={"product-" + index}
              image={get(cost, "insurancePlan.icon")}
              name={get(cost, "insurancePlan.name")}
              description={get(cost, "insurancePlan.description")}
              costPerMonth={
                <>
                  {`${integer} ${currency} `}
                  <sup>{decimal?.slice(0, 2)}</sup>
                </>
              }
              commission={get(cost, "commission.id")}
              costAll={(+get(cost, "cost") * 12).toFixed(2)}
              state={isSelected(cost) ? "disabled" : "default"}
              onClick={() => (isSelected(cost) ? onRemove(cost) : onAdd(cost))}
              translation={DETAIL.TEMPLATES.PRODUCTS.CARD}
              commissions={commissions}
              onChangeCommission={(id) =>
                onChangeCommission({
                  insurancePlan: { id: get(cost, "insurancePlan.id") },
                  commission: { id },
                })
              }
            />
          );
        })}
      </div>
    </Card>
  );
};

export default All;
