import { Alert, Popover, notification } from "antd";
import cuid from "cuid";
import { get, isEmpty, isNil } from "lodash";
import moment from "moment";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdArrowBackIos, MdReportGmailerrorred, MdSend } from "react-icons/md";
import Button from "shared/components/Button";
import Header from "shared/components/Header";
import Input from "shared/components/Input";
import Select from "shared/components/Select";
import TextEditor from "shared/components/TextEditor";
import { NAME_SPACES } from "shared/locales/constants";
import { StoreContext } from "shared/store";
import format from "string-template";
import {
  CONTACT_POINT_SYSTEM,
  CURRENCY_SYMBOL,
  DATE_FORMAT,
  REGEX,
} from "utils/constants";
import Attachments from "./widgets/Attachments";

const DUMMY_ICON = "https://icon-library.com/images/icon-url/icon-url-15.jpg";

const View = ({
  project,
  onNext,
  loading,
  attachments,
  setAttachments,
  onBack,
  data: costs,
}) => {
  const { t } = useTranslation(NAME_SPACES.PRIVATE.BROKER.PROJECT);
  const { user } = useContext(StoreContext);
  const DETAIL = t("DETAIL", { returnObjects: true });
  const editorCore = useRef(null);
  const [telecoms, setTelecoms] = useState(
    get(project, "contact.telecoms", [])
      .filter((telecom) => telecom.system === CONTACT_POINT_SYSTEM.EMAIL)
      .map((telecom) => ({
        label: telecom.value,
        value: telecom.id,
      }))
  );

  const phoneNumber = get(user, "phone");

  const email = get(user, "email");

  const address = get(
    get(user, "organization.telecoms", []).find(
      (telecom) => telecom.system === CONTACT_POINT_SYSTEM.ADDRESS
    ),
    "value",
    ""
  );

  const defaultValue = useMemo(
    () => ({
      blocks: [
        {
          type: "paragraph",
          style: { fontSize: 20 },
          data: {
            text: format(DETAIL.TEMPLATES.QUOTE.MAIL.HEADER, {
              gender:
                DETAIL.TEMPLATES.QUOTE.MAIL[
                  get(project, "contact.user.gender", "")
                ],
              firstName: get(project, "contact.user.firstname", ""),
              lastName: get(project, "contact.user.lastname", ""),
            }),
          },
        },
        {
          type: "paragraph",
          data: {
            text: DETAIL.TEMPLATES.QUOTE.MAIL.P1,
          },
        },
        {
          type: "paragraph",
          data: {
            text: format(DETAIL.TEMPLATES.QUOTE.MAIL.P2, {
              currentDate: moment().format(DATE_FORMAT),
            }),
          },
        },
        {
          type: "paragraph",
          data: {
            text: DETAIL.TEMPLATES.QUOTE.MAIL.P3,
          },
        },
        {
          type: "paragraph",
          data: {
            text: format(DETAIL.TEMPLATES.QUOTE.MAIL.P4, {
              endOfValidityDate:
                moment().format("MM") === "12"
                  ? moment().endOf("M").format(DATE_FORMAT)
                  : moment().add(30, "d").format(DATE_FORMAT),
            }),
          },
        },
        {
          type: "paragraph",
          data: {
            text: "",
          },
        },
        {
          type: "paragraph",
          data: {
            text: `<div style="display: flex;gap: 8px;">
        ${costs?.reduce(
          (accumulator, { insurancePlan, cost }) =>
            (accumulator += `<div style="display:flex;flex-direction:column;row-gap:8px;border-radius:8px;padding:8px 12px;cursor:pointer;background-color:#fff;border:1px solid #A5AAC2;min-width:116px;">
                          <img
                            style="height:26px;width:76px;object-fit:contain;"
                            src="${
                              isNil(insurancePlan.icon)
                                ? DUMMY_ICON
                                : insurancePlan.icon
                            }"
                            alt="${insurancePlan.name}"
                          />
                          <div style="font-weight:700;font-size:24px;line-height:28px;color:#0E93E5;">${cost} ${
              CURRENCY_SYMBOL.EUR
            }</div>
                          <div style="font-weight:600;font-size:14px;line-height:18px;">
                            ${insurancePlan.name}
                            <span style="display:block;font-weight:400;font-size:12px;line-height:14px;color:#A5AAC2;">${
                              insurancePlan.description
                            }</span>
                          </div>
                        </div>`),
          ""
        )}
        </div>`,
          },
        },
        {
          type: "paragraph",
          data: {
            text: "",
          },
        },
        {
          type: "paragraph",
          data: {
            text: DETAIL.TEMPLATES.QUOTE.MAIL.P5,
          },
        },
        {
          type: "paragraph",
          data: {
            text: format(DETAIL.TEMPLATES.QUOTE.MAIL.P6, {
              phoneNumber,
            }),
          },
        },
        {
          type: "paragraph",
          data: {
            text: "",
          },
        },
        {
          type: "paragraph",
          data: {
            text: DETAIL.TEMPLATES.QUOTE.MAIL.SINCERLY,
          },
        },
        {
          type: "paragraph",
          data: {
            text: "---",
          },
        },
        {
          type: "paragraph",
          data: {
            text: DETAIL.TEMPLATES.QUOTE.MAIL.YOUR_ADVISOR,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `${get(user, "firstname", "")} ${get(user, "lastname", "")}`,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            ${DETAIL.TEMPLATES.QUOTE.MAIL.TEL}: ${phoneNumber}
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            ${DETAIL.TEMPLATES.QUOTE.MAIL.EMAIL}: ${email}
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            ${DETAIL.TEMPLATES.QUOTE.MAIL.ADDRESS}: ${address}
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            <p style="font-size:10px">${DETAIL.TEMPLATES.QUOTE.MAIL.F1}</p>
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            <p style="font-size:10px">${format(DETAIL.TEMPLATES.QUOTE.MAIL.F2, {
              brokerName: get(user, "organization.name"),
              brokerOrias: get(user, "organization.broker.orias"),
              brokerAddress: address,
            })}</p>
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            <p style="font-size:10px">${DETAIL.TEMPLATES.QUOTE.MAIL.F3}</p>
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            <p style="font-size:10px">${DETAIL.TEMPLATES.QUOTE.MAIL.F4}</p>
          `,
          },
        },
        {
          type: "paragraph",
          data: {
            text: `
            <p style="font-size:10px">${DETAIL.TEMPLATES.QUOTE.MAIL.F5}</p>
          `,
          },
        },
      ],
    }),
    []
  );

  const unlinkAttachment = (id) =>
    setAttachments((prev) => [...prev.filter((item) => item.id !== id)]);

  const onAddEmail = (value) => {
    if (REGEX.EMAIL.test(value)) {
      setTelecoms([...telecoms, { label: value, value: cuid() }]);
      value = "";
    } else {
      notification.error({
        message: DETAIL.TEMPLATES.QUOTE.MAIL.INVALID_EMAIL,
      });
    }
  };

  const onDeleteEmail = (email) => {
    setTelecoms(telecoms.filter(({ value }) => value !== email));
  };

  return (
    <>
      <Header
        key={"header"}
        title={DETAIL.TEMPLATES.QUOTE.TITLE}
        actions={[]}
      />
      <div className="quote-template">
        <div className="quote-template__header">
          <span>{DETAIL.TEMPLATES.QUOTE.TITLE}</span>
        </div>
        <Alert
          type="warning"
          showIcon
          icon={<MdReportGmailerrorred size={16} />}
          message={DETAIL.TEMPLATES.QUOTE.WARNING}
        />
        <div className="quote-template__mail">
          <div className="quote-template__mail-header">
            <Select
              label={DETAIL.TEMPLATES.QUOTE.MAIL.TO}
              mode="tags"
              maxTagCount="responsive"
              value={telecoms}
              options={telecoms}
              onSelect={onAddEmail}
              onDeselect={onDeleteEmail}
            />
            <Input
              label={DETAIL.TEMPLATES.QUOTE.MAIL.SUBJECT.label}
              value={DETAIL.TEMPLATES.QUOTE.MAIL.SUBJECT.text}
              disabled
            />
          </div>
          <div className="quote-template__mail-label">
            {DETAIL.TEMPLATES.QUOTE.MAIL.MESSAGE}
          </div>
          <div className="quote-template__mail-body">
            <TextEditor defaultValue={defaultValue} ref={editorCore} readOnly />
          </div>
          <Attachments
            unlinkAttachment={unlinkAttachment}
            loading={loading}
            list={attachments}
          />
        </div>
        <div className="d-flex justify--between">
          <Button
            onClick={onBack}
            type="secondary--link"
            disabled={project.locked}
          >
            <MdArrowBackIos size={16} />
            {DETAIL.TEMPLATES.COMPARISON.PREVIOUS}
          </Button>
          <div className="popover-wrapper">
            {isEmpty(telecoms) ? (
              <Popover content={DETAIL.TEMPLATES.QUOTE.POPOVER}>
                <Button type="secondary" disabled={true}>
                  {DETAIL.TEMPLATES.QUOTE.SEND}
                  <MdSend size={16} />
                </Button>
              </Popover>
            ) : (
              <Button onClick={() => onNext(telecoms)} type="secondary">
                {DETAIL.TEMPLATES.QUOTE.SEND}
                <MdSend size={16} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
